import React from "react";

function NonAssuredRegisteredCorporates() {
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item"
              id="registered-corporates-tab"
              data-bs-toggle="tab"
              data-bs-target="#registered-corporates"
              type="button"
              role="tab"
              aria-controls="registered-corporates"
              aria-selected="true"
            >
              Non Assured Registered Corporates
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="select-month-tab"
              data-bs-toggle="tab"
              data-bs-target="#select-month"
              type="button"
              role="tab"
              aria-controls="select-month"
              aria-selected="false"
            >
              Select Month
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="registered-corporates"
            role="tabpanel"
            aria-labelledby="registered-corporates-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Employer name</th>
                          <th>Employer Code</th>
                          <th>Assured / Non Assured</th>
                        <th>No. of employees Uploaded</th>
                        <th>No. of employees Approved</th>
                        <th>No. of employees Rejected</th>
                        <th>Limit assigned amt (BY STP)</th>
                        <th>Limit Utilized</th>
                        <th>Utilization % </th>
                        <th>Unpaid Count</th>
                        <th>Unpaid Amount</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="select-month"
            role="tabpanel"
            aria-labelledby="select-month-tab"
          >
            Select Month
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonAssuredRegisteredCorporates;
