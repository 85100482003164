import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DownloadTableExcel } from "react-export-table-to-excel";
import { APIURL } from "../../../Config";
import axios from "axios";
import { Select } from "antd";
import { Form } from "react-bootstrap";

const PendingPage = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  const tableRef = React.useRef(null);
  const [selectDate, setSelectDate] = useState(false);

  const [selectValue, setSelectValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [engineerDetail, setEngineerDetail] = useState([]);
  useEffect(() => {
    fetchClientDetails();
    fetchAssignTo();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAssignTo = async () => {
    await axios
      .get(`${APIURL}/api/EngineerDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setEngineerDetail(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 7);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);
  const handleSubmit = () => {
    console.log(":::::::", dateValue1, dateValue2);
    let url =
      role === "Engineer"
        ? `${APIURL}/api/V2/clientStatusEngineer/${ids}/0`
        : `${APIURL}/api/V2/clientStatus/${ids}/0`;
    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/V2/clientStatusEngineerByClientsAndAssignTo/${ids}/0/${selectValue}/${assignTo}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/V2/clientStatusEngineerByClients/${ids}/0/${selectValue}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/V2/clientStatusEngineerByAssignTo/${ids}/0/${assignTo}`;
    }

    axios
      .request(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div class="pt-4">
      <h4 className="text-center my-3">Pending</h4>

      <div className="pt-3">
        <div className="card">
          <div className="p-3 pt-4">
            <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div>
                <DownloadTableExcel
                  filename="Pending"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button
                    className="btn btn-success mb-3 mb-md-0"
                    style={{ width: "120px", background: "blue" }}
                  >
                    Download
                  </button>
                </DownloadTableExcel>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center">
                {!selectDate ? (
                  <button
                    className="btn btn-info"
                    style={{ width: "150px" }}
                    onClick={() => setSelectDate(true)}
                  >
                    Filters
                  </button>
                ) : (
                  <>
                    <div className="d-flex" style={{ width: "500px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="mt-2 mx-1">
                          <Form.Select
                            options={clientDetails}
                            value={selectValue}
                            type="number"
                            className="form-control ml-2"
                            onChange={(e) => setSelectValue(e.target.value)}
                          >
                            <option value="">Select Client</option>
                            {clientDetails.map((ids) => (
                              <option value={ids.id} key={ids.id}>
                                {ids.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="mt-2 mx-2">
                          <Form.Select
                            options={engineerDetail}
                            value={assignTo}
                            type="text"
                            className="form-control ml-2"
                            onChange={(e) => setAssignTo(e.target.value)}
                          >
                            <option value="">Select Assign To</option>

                            {engineerDetail.map((ids) => (
                              <option value={ids.id} key={ids.id}>
                                {ids.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </LocalizationProvider>
                      <div className="mt-2 mx-2">
                        <button
                          className="btn btn-success"
                          style={{
                            background: "#66a725",
                            width: "100px",
                          }}
                          onClick={() => handleSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="table-responsive mt-3">
              <table class="table table-boarded" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Case No</th>
                    <th>Applicant Name</th>
                    <th>Mobile Number</th>
                    <th>Address</th>
                    <th>Client Name</th>
                    <th>Assign to</th>
                    {/* <th>Case Reference Number</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.caseReferenceNumber}</td>
                      <td>{item?.applicantName}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.address}</td>
                      <td>{item?.clientName}</td>
                      <td>{item?.assignBy}</td>
                      {/* <td>{item?.caseReferenceNumber}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingPage;
