import React, { useEffect, useState } from "react";
import { APIURL } from "../Config";
import axios from "axios";
import moment from "moment";

function LoanDetails({ lid }) {
  const [empData, setEmpData] = useState([]);

  useEffect(() => {
    getClientDetailsMagic(lid);
  }, [lid]);

  const getClientDetailsMagic = (loanId) => {
    axios
      .request(`${APIURL}/loanCardDetails/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEmpData(response?.data);
        console.log("response::::", response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="pt-3">
        <div className="card">
          <div className="card-body">
            <div className="mt-3">
              <div className="pt-1" style={{ backgroundColor: "#a6df6d" }}>
                <h5 className="text-center">Profile</h5>
              </div>
              <div className="row ">
                <div
                  className="col-lg-5 col-md-5 col-sm-12 d-flex mt-5"
                  style={{ justifyContent: "center" }}
                >
                  <img
                    src={empData?.clientphotourl}
                    className=""
                    height="250px"
                    width="250px"
                  />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 " style={{ fontSize: "14px" }}>
                  <div className="row mt-4">
                    <b className="col-4 col-xs-6">Name </b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.clientName}
                    </p>
                  </div>
                  <div className="row">
                    <b className="col-4 col-xs-6">Loan Id </b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.loanId}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Approval dt </b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {" "}
                      {/* {borrowerData?.disbursement?.disbdate} */}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Disbursement Dt</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.disdate
                        ? moment(empData.disdate).format("DD-MM-YYYY")
                        : ""}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Mobile</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.mobileNo}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Address</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.clientaddress}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Pan No</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.clientpancard}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Email </b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {" "}
                      {empData?.clientEmailId}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Product</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.loantype}
                    </p>
                  </div>{" "}
                  <div className="row">
                    <b className="col-4 col-xs-6">Limit</b>
                    <p className="col-8 col-xs-6" style={{ fontSize: "16px" }}>
                      {empData?.financeAmt}
                    </p>
                  </div>{" "}
                </div>
              </div>
              <div
                className="table-responsive pt-1 mt-3"
                style={{ backgroundColor: "#a6df6d" }}
              >
                <h5 className="text-center">Transaction Summary</h5>
              </div>
              <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>S No.</th>
                    <th style={{ textAlign: "center" }}>Date Time</th>
                    <th style={{ textAlign: "center" }}>Payee</th>
                    <th style={{ textAlign: "center" }}>Withdrawal</th>
                    <th style={{ textAlign: "center" }}>Payment</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
</div>
              <div
                style={{
                  fontFamily: "PostGrotesk",

                  borderRadius: "10px",
                }}
              >
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div
                      className="mb-3"
                      style={{
                        borderRadius: "0px",
                        border: "2px solid #CACACA",
                      }}
                    >
                      <h5 class="p-3 pb-0">Loan Details</h5>
                      <hr />

                      <table
                        class="table table-striped"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ border: "none" }}>Loan Id</th>
                            <th style={{ border: "none" }}>
                              {empData.loanId || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Client Id </th>
                            <th style={{ border: "none" }}></th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Sanction Amount </th>
                            <th style={{ border: "none" }}>
                              {empData.sanctionAmount || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Scheme No </th>
                            <th style={{ border: "none" }}>
                              {empData.schemenumber || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Product</th>
                            <th style={{ border: "none" }}>
                              {empData.product || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Period</th>
                            <th style={{ border: "none" }}>
                              {empData.period || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>EMI</th>
                            <th style={{ border: "none" }}>
                              {empData.emi || "-"}
                            </th>
                          </tr>
                        </tbody>
                        <div className="mt-3">
                          <span className="fw-bold">
                            Borrower KYC Address :-
                          </span>
                          <br />
                          <span className="">{empData.clientaddress}</span>
                        </div>
                      </table>
                    </div>
                  </div>
                  <div className=" col-lg-4 col-md-4 col-sm-12">
                    <div
                      className="mb-3"
                      style={{
                        borderRadius: "0px",
                        border: "2px solid #CACACA",
                      }}
                    >
                      <h5 class="p-3 pb-0">Borrower Details</h5>
                      <hr />

                      <table
                        class="table table-striped"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ border: "none" }}>App Client Id </th>
                            <th style={{ border: "none" }}>
                              {empData.appClientId || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Name</th>
                            <th style={{ border: "none" }}>
                              {empData.clientName || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Gender</th>
                            <th style={{ border: "none" }}>
                              {empData.clientGender || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>C/O </th>
                            <th style={{ border: "none" }}>
                              {empData.careOf || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Relation</th>
                            <th style={{ border: "none" }}>
                              {empData.relation || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Date Of Birth</th>
                            <th style={{ border: "none" }}>
                              {empData.clientDob
                                ? moment(empData.clientDob).format("DD-MM-YYYY")
                                : "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Mobile</th>
                            <th style={{ border: "none" }}>
                              {empData.mobileNo || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>KYC Aadhar Card </th>
                            <th style={{ border: "none" }}>
                              {empData.aadhaarNo || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>PAN Name </th>
                            <th style={{ border: "none" }}>
                              {empData.clientpancard || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Email id </th>
                            <th style={{ border: "none" }}>
                              <div>
                                {empData.clientEmailId?.split("").length > 15 &&
                                  empData.clientEmailId
                                    ?.split("")
                                    .slice(0, 15)
                                    .join("")}
                              </div>
                              <div>
                                {empData.clientEmailId?.split("").length > 15 &&
                                  empData.clientEmailId
                                    ?.split("")
                                    .slice(15)
                                    .join("")}
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Cibile Score </th>
                            <th style={{ border: "none" }}>
                              {empData.score || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Office Pincode </th>
                            <th style={{ border: "none" }}>
                              {empData.officeaddress || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Pincode</th>
                            <th style={{ border: "none" }}>
                              {empData.pincode || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Date of Exit</th>
                            <th style={{ border: "none" }}>
                              {empData.dateofexit || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              Client Account No
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.clientAccountNo || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Employee Type </th>
                            <th style={{ border: "none" }}>
                              {empData.employeeType || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Employee Name </th>
                            <th style={{ border: "none" }}>
                              <div>
                                {empData.employerName?.split("").length > 15 &&
                                  empData.employerName
                                    ?.split("")
                                    .slice(0, 22)
                                    .join("")}
                              </div>
                              <div>
                                {empData.employerName?.split("").length > 15 &&
                                  empData.employerName
                                    ?.split("")
                                    .slice(22, 44)
                                    .join("")}
                              </div>
                              <div>
                                {empData.employerName?.split("").length > 15 &&
                                  empData.employerName
                                    ?.split("")
                                    .slice(44)
                                    .join("")}
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className=" col-lg-4 col-md-4 col-sm-12">
                    <div
                      className="mb-3"
                      style={{
                        borderRadius: "0px",
                        border: "2px solid #CACACA",
                      }}
                    >
                      <h5 class="p-3 pb-0">Account Aggregator</h5>
                      <hr />

                      <table
                        class="table table-striped"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ border: "none" }}>
                              Account Aggregator <br /> Name{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              <div>
                                {empData.accountAggregatorName?.split("")
                                  .length > 15 &&
                                  empData.accountAggregatorName
                                    ?.split("")
                                    .slice(0, 15)
                                    .join("")}
                              </div>
                              <div>
                                {empData.accountAggregatorName?.split("")
                                  .length > 15 &&
                                  empData.accountAggregatorName
                                    ?.split("")
                                    .slice(15, 45)
                                    .join("")}
                              </div>
                              <div>
                                {empData.accountAggregatorName?.split("")
                                  .length > 15 &&
                                  empData.accountAggregatorName
                                    ?.split("")
                                    .slice(45)
                                    .join("")}
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              First Month Salary{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.firstMonthSalary || "-"}
                            </th>
                          </tr>{" "}
                          <tr>
                            <th style={{ border: "none" }}>
                              Second Month Salary{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.secondMonthSalary || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              Third Month Salary
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.thirdMonthSalary || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>Account No </th>
                            <th style={{ border: "none" }}>
                              {empData.clientAccountNo || "-"}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="mt-2"
                      style={{
                        borderRadius: "0px",
                        border: "2px solid #CACACA",
                      }}
                    >
                      <h5 class="p-3 pb-0">EPF Details</h5>
                      <hr />
                      <table
                        class="table table-striped"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr>
                            <th style={{ border: "none" }}>
                              Equifax Score
                              <br /> Microfinance{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.equifaxScoreMicrofinance || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              Equifax Score Retail
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.equifaxScoreRetail || "-"}
                            </th>
                          </tr>

                          <tr>
                            <th style={{ border: "none" }}>
                              Establishment Name{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              <div>
                                {empData.establishmentName?.split("").length >
                                  15 &&
                                  empData.establishmentName
                                    ?.split("")
                                    .slice(0, 15)
                                    .join("")}
                              </div>
                              <div>
                                {empData.establishmentName?.split("").length >
                                  15 &&
                                  empData.establishmentName
                                    ?.split("")
                                    .slice(15, 40)
                                    .join("")}
                              </div>
                              <div>
                                {empData.establishmentName?.split("").length >
                                  15 &&
                                  empData.establishmentName
                                    ?.split("")
                                    .slice(40)
                                    .join("")}
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>EPF Employer </th>
                            <th style={{ border: "none" }}>
                              {empData.epfisemployed || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              EPF Total Amount{" "}
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.epftotalamount || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              EPF Employees Count
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.epfemployeescont || "-"}
                            </th>
                          </tr>
                          <tr>
                            <th style={{ border: "none" }}>
                              EPF Date Of Joining
                            </th>
                            <th style={{ border: "none" }}>
                              {empData.epfdateofjoining || "-"}
                            </th>
                          </tr>
                          {/* <tr>
                                  <th style={{ border: "none" }}>Client Name </th>
                                  <th style={{ border: "none" }}>{empData.clientName || "-"}</th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Account Aggregator Name</th>
                                  <th style={{ border: "none" }}>
                                    {empData.accountAggregatorName ||
                                      "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Current Organisation Name</th>
                                  <th style={{ border: "none" }}>
                                    {empData.currentOrganisationName ||
                                      "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>EPF Name</th>
                                  <th style={{ border: "none" }}>{empData.epfName || "-"}</th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Sim Owner Name</th>
                                  <th style={{ border: "none" }}>
                                    {empData.simOwnerName || "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>First Reference Name</th>
                                  <th style={{ border: "none" }}>
                                    {empData.firstRefName || "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>First Reference Relation</th>
                                  <th style={{ border: "none" }}>
                                    {empData.firstRefRelation || "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>First Reference Contact No</th>
                                  <th style={{ border: "none" }}>
                                    {empData.firstRefMobile || "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Friend Name</th>
                                  <th style={{ border: "none" }}>{empData.friendName || "-"}</th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Friend Contact No</th>
                                  <th style={{ border: "none" }}>
                                    {empData.friendMobileno || "-"}
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ border: "none" }}>Friend No Of Years</th>
                                  <th style={{ border: "none" }}>
                                    {empData.friendNoOfYears || "-"}
                                  </th>
                                </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoanDetails;
