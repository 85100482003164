import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../image/Salarytap_logo_horizontal-light_800X239_colored.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Spinner from "react-bootstrap/Spinner";

// import { APIURL } from "../../Config";
import NavbarLogin from "./Navbar/Navbar";
import { APIURL } from "../../Config";

// import { useAppContext } from "../../Context/Listdata";

export default function CreateYourWorkspace() {
  // const { setAuthenticated } = useAppContext();
  const [show, setShow] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [paramsEmail, setParamsEmail] = useState("");
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    emailId: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.string().required("required"),
  });

  const backToWeb = () => {
    history("/");
    localStorage.clear();
  };

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }
  const clearOtpFields = () => {
    setOtp(Array(6).fill(""));
    otpBoxReference.current[0].focus();
  };
  const handelSubmitOtp = async () => {
    setIsLoader(true);

    const hasEmptyString = otp.some((value) => value === "");

    if (!hasEmptyString) {
      const data = {
        otp: parseInt(otp.join("")),
      };

      if (data) {
        let dto = JSON.stringify({
          email: paramsEmail,
          otp: String(data.otp),
        });
        setIsLoader(true);
        axios
          .request(`${APIURL}/corporate/otpverify`, {
            method: "POST",
            data: dto,
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response::", response);
            setIsLoader(false);
            if (response?.data?.success == true) {
              clearOtpFields();
              history(
                `/greenfinch/finalCreateYourWorkspace?token=${response?.data?.token}&email=${paramsEmail}`
              );
              toast.success("OTP Verification Successful");
            } else {
              toast.error("OTP Verification failed");
            }
          })
          .catch((error) => {
            setIsLoader(false);
            console.log(error);
          });
      }
    } else {
      toast.error("All fields required");
    }
  };

  React.useEffect(() => {
    // const tokenStatus = localStorage.getItem("token");
    // const role = localStorage.getItem("role");
    // //  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // if (!tokenStatus == "") {
    //   history("/admin");
    // }
  }, []);

  return (
    <>
      {show && (
        <Formik
          initialValues={{ emailId: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const data = {
              email: values.emailId,
              createflag: "create",
            };
            setIsLoader(true);
            axios
              .request(`${APIURL}/corporate/login`, {
                method: "POST",
                data: data,
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                setIsLoader(false);
                console.log("response", response);
                setParamsEmail(response?.data?.emailId);
                if (response?.data?.workspaceId !== null) {
                  toast.warning("Workspace already create please login...");
                  history("/");
                  localStorage.clear();
                } else {
                  setIsLoader(false);
                  toast.success("Otp Send Successfully...");
                  setShow(false);
                  setShowOtp(true);
                }
              })
              .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
                toast.warning("User not exist...");
              });
          }}
        >
          {({ touched, errors }) => (
            <>
              <div className="Loginform-container">
                <Form className="Loginform ">
                  <div>
                    <div className="">
                      <div className="d-flex justify-content-center mb-4">
                        <img src={logo} alt="logo" width="35%" height="100%" />
                      </div>
                      <div className="d-flex justify-content-center mb-2">
                        <span className="fs-5 ">
                          Hi, let us begin with entering your
                          <b className=""> email address</b>
                        </span>
                      </div>
                      <div className="d-flex justify-content-center mb-5">
                        <span className="" style={{ fontSize: "18px" }}>
                          (Only Employers can create a workspace)
                        </span>
                      </div>
                      <label className="fs-5 fw-medium ">
                        Enter your work email*
                      </label>
                      <Field
                        type="email"
                        name="emailId"
                        placeholder="Enter work email"
                        className={`form-control mt-2
                          ${
                            touched.emailId && errors.emailId
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="emailId"
                        className="invalid-feedback"
                      />

                      <button
                        className="btn btn-success mt-3"
                        type="submit"
                        style={{ backgroundColor: "#66a725", color: "#fff" }}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </button>
                      {/* <hr /> */}
                      <div className="d-flex justify-content-end align-items-center">
                        {" "}
                        <p className="mt-4 pe-4">
                          By continuing, you are agreeing to our main services
                          agreement and user terms of service. Other disclosures
                          can be seen in our privacy policy
                        </p>
                        <button
                          className="btn btn-light mt-2"
                          type="button"
                          style={{ width: "70px" }}
                          onClick={() => backToWeb()}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
      {showOtp && (
        <div className="Loginform-container">
          <div className="Loginform ">
            <div className="d-flex justify-content-center mb-4">
              <img src={logo} alt="logo" width="35%" height="100%" />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center mb-5">
                  <span className="fs-3 fw-semibold ">
                    Check your email for a code
                  </span>
                </div>
                <div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    Enter OTP code sent to your email
                  </p>
                </div>

                <div className="row ">
                  <div className="col-lg-12 text-center">
                    <div
                      className=""
                      style={{
                        flexDirection: "row",
                        columnGap: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          value={digit}
                          maxLength={1}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                          ref={(reference) =>
                            (otpBoxReference.current[index] = reference)
                          }
                          style={{ height: "45px", width: "44px" }}
                          className={`border w-12 h-12 text-4xl text-black p-3 rounded-md block bg-light focus:border-2 focus:outline-none appearance-none text-center`}
                        />
                      ))}
                    </div>

                    <p
                      className={`text-lg text-white mt-4 ${
                        otpError ? "error-show" : ""
                      }`}
                    >
                      {otpError}
                    </p>

                    <button
                      onClick={() => handelSubmitOtp()}
                      className="btn btn-success mt-3 w-50"
                      style={{ backgroundColor: "#66a725", color: "#fff" }}
                      type="submit"
                    >
                      {isLoader ? (
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-light mt-2"
                        type="button"
                        style={{ width: "70px" }}
                        onClick={() => {
                          setShow(true);
                          setShowOtp(false);
                          clearOtpFields();
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      )}
    </>
  );
}
