import React, { useEffect, useRef, useState } from "react";
import logo from "../../image/Salarytap_social_profile.png";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import Profile from "./Page/Profile";

import Setting from "./Page/Setting";
import ManageEmploye from "./Page/ManageEmploye";
import TalkToSales from "./Page/TalkToSales";
import Admin from "./Page/Admin";
import CreateCorporateWorkspace from "./Page/CreateCorporateWorkspace";
import { APIURL } from "../../Config";
import axios from "axios";
import AssuranceAgreement from "./Page/AssuranceAgreement";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppContext } from "../utils/Appcontex";
// import SideBar from "./SideBar";
// import StpSideBar from "../Stp/StpSideBar";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CaseDetailsSample from "../../image/CaseDetailsSample.xlsx";
import Navbar from "../Pages/Navbar/Navbar";
import Approved from "./Page/Approved";
import Rejected from "./Page/Rejected";
import All from "./Page/All";
import PendingPage from "./Page/PendingPage";
import AddUploadData from "./AddUploadData";

export default function SideBar() {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role"); //Engineer
  console.log("role::: ", role);

  const workspaceId = localStorage.getItem("workspace");
  const emailid = localStorage.getItem("emailId");
  // const [activeIndex, setActiveIndex] = useState(5);
  // const [key, setKey] = useState("ManageEmployees");
  const [activeTab, setActiveTab] = useState("profile");
  const [workSpace, SetWorkSpace] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectValue, setSelectValue] = useState("");

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openList, setOpenList] = useState(true);
  const { setOpen, key, setKey, activeIndex, setActiveIndex } = useAppContext();
  const [listItem, setListItem] = useState("Upload");
  const [showUpload, SetShowUpload] = useState(false);

  //  const [isLoader, setIsLoader] = useState(false);
  //  const fileInputRef = useRef(null);
  useEffect(() => {
    const tokenStatus = localStorage.getItem("token");
    if (
      tokenStatus === "undefined" ||
      tokenStatus === "" ||
      !tokenStatus ||
      tokenStatus === "null"
    ) {
      history("/");
    } else {
      history("/greenfinch/dashboard");
    }
  }, []);
  const [clientDetails, setClientDetails] = useState([]);
  useEffect(() => {
    fetchClientDetails();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getListItem = (item) => {
    setListItem(item);
  };

  const LoginSchema = Yup.object().shape({
    file: Yup.mixed().required("A file is required"),
    clientId: role === "Engineer" ? Yup.number().required("Required") : "",
    checkboxes: Yup.object()
      .shape({
        residenceVerificationStatus: Yup.number(),
        collateralVerificationStatus: Yup.number(),
        businessVerificationStatus: Yup.number(),
      })
      .test(
        "at-least-one-checked",
        "At least one verification must be checked",
        (values) => {
          // Check if at least one checkbox value is 0 (checked)
          return (
            values?.residenceVerificationStatus === 0 ||
            values?.collateralVerificationStatus === 0 ||
            values?.businessVerificationStatus === 0
          );
        }
      ),
  });
  const UploadSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    mobile: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    clientId: role === "Engineer" ? Yup.number().required("Required") : "",
    address: Yup.string().required("Required"),
    caseRefNo: Yup.string().required("Required"),
    checkboxes: Yup.object()
      .shape({
        residenceVerificationStatus: Yup.number(),
        collateralVerificationStatus: Yup.number(),
        businessVerificationStatus: Yup.number(),
      })
      .test(
        "at-least-one-checked",
        "At least one verification must be checked",
        (values) => {
          // Check if at least one checkbox value is 0 (checked)
          return (
            values?.residenceVerificationStatus === 0 ||
            values?.collateralVerificationStatus === 0 ||
            values?.businessVerificationStatus === 0
          );
        }
      ),
  });
  const handleClick = (index, tabKey, tabId) => {
    setActiveIndex(index);
    setKey(tabKey);
    setActiveTab(tabId);
    if (window.innerWidth <= 768) {
      setSidebarVisible(false);
    }
  };

  // React.useEffect(() => {
  //   if (!token == "") {
  //     history("/salarytap/dashboard");
  //     WorkspaceDetails();
  //   } else {
  //     history("/salarytap/login");
  //   }
  // }, []);

  // function WorkspaceDetails() {
  //   const data = { emailid, workspaceId };
  //   setIsLoader(true);
  //   axios
  //     .request(`${APIURL}/corporate/getprofiledetails`, {
  //       method: "POST",
  //       data: data,
  //       headers: {
  //         Authentication: token,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       SetWorkSpace(response?.data);
  //       setIsLoader(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoader(false);
  //     });
  // }
  console.log("workSpace", workSpace);
  const [selectedImage, setSelectedImage] = useState("");
  const fileInputRef = useRef(null);
  const [previewError, setPreviewError] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedImage(file);
      setPreviewError(false);
    } else {
      setSelectedImage(null);
      setPreviewError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      setPreviewError(true);
      return;
    }
    // const formData = new FormData();
    // formData.append("file", selectedImage);
    // formData.append("emailid", emailid);
    // console.log("formData", formData);
    // for (let pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    // setIsLoader(true);
    // axios
    //   .request(`${APIURL}/corporate/uploadlogo`, {
    //     method: "POST",
    //     data: formData,
    //     headers: {
    //       Authentication: token,
    //     },
    //   })
    //   .then((response) => {
    //     setIsLoader(false);
    //     console.log("response", response);
    //     setShowModal(false);
    //     WorkspaceDetails();
    //     toast.success("Uploaded..");
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     setIsLoader(false);
    //     setShowModal(false);
    //     toast.warning("User not exist...");
    //   });
  };

  const handleModalClose = () => {
    setSelectedImage("");
    setShowModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth > 768) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    };
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleOpenList = () => {
    setOpenList(!openList);
  };

  return (
    <>
      <div className="d-flex bg-light">
        {sidebarVisible && (
          <div
            className="flex-column flex-shrink-0 p-3 border sidebar-toggle"
            style={{
              width: 280,
              height: "93.4vh",
              display: "flex",
              background: "#fff",
              position: "sticky",
              top: "0",
            }}
          >
            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
              }}
            ></div>

            <ul className="nav nav-pills flex-column mb-auto">
              <li
                className={"nav-link nav-itm mb-1 active"}
                onClick={() => {
                  handleClick(0);
                  setKey("Profile");
                  setOpen(false);
                  toggleOpenList();
                }}
                style={{
                  backgroundColor: "#02CCFE",
                  color: "#000",
                  cursor: "pointer",
                }}
              >
                CPV
              </li>
              {openList && (
                <div class="row">
                  <div>
                    <div class="list-group" id="list-tab" role="tablist">
                      <a
                        class="list-group-item list-group-item-action active"
                        id="list-home-list"
                        data-bs-toggle="list"
                        href="#list-home"
                        role="tab"
                        aria-controls="list-home"
                        onClick={() => {
                          getListItem("Upload");
                        }}
                      >
                        Upload
                      </a>
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-profile-list"
                        data-bs-toggle="list"
                        href="#list-profile"
                        role="tab"
                        aria-controls="list-profile"
                        onClick={() => {
                          getListItem("Pending");
                        }}
                      >
                        Pending
                      </a>
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-profile-list"
                        data-bs-toggle="list"
                        href="#list-profile"
                        role="tab"
                        aria-controls="list-profile"
                        onClick={() => {
                          getListItem("Approved");
                        }}
                      >
                        Approved
                      </a>
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-messages-list"
                        data-bs-toggle="list"
                        href="#list-messages"
                        role="tab"
                        aria-controls="list-messages"
                        onClick={() => {
                          getListItem("Rejected");
                        }}
                      >
                        Rejected
                      </a>
                      {/* <a
                        class="list-group-item list-group-item-action"
                        id="list-settings-list"
                        data-bs-toggle="list"
                        href="#list-settings"
                        role="tab"
                        aria-controls="list-settings"
                        onClick={() => {
                          getListItem("All");
                        }}
                      >
                        All
                      </a> */}
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        )}
        <div className="container">
          {/* <h1 className="text-center my-3">Dashboard</h1> */}
          {listItem === "Upload" && (
            <div>
              {/* <h1 className="text-center my-3">Upload Data</h1> */}

              <div className="card p-4">
                <div className="d-flex justify-content-end p-2">
                  {!showUpload ? (
                    <button
                      className="mr-3 mt-2 btn"
                      style={{ width: "120px", background: "#02CCFE" }}
                      onClick={() => SetShowUpload(true)}
                    >
                      Add
                    </button>
                  ) : (
                    <button
                      className="mr-3 mt-2 btn btn-secondary"
                      style={{ width: "120px" }}
                      onClick={() => SetShowUpload(false)}
                    >
                      Back
                    </button>
                  )}
                </div>
                {!showUpload ? (
                  <Formik
                    initialValues={{
                      file: null,
                      clientId: "",
                      checkboxes: {
                        residenceVerificationStatus: 1,
                        collateralVerificationStatus: 1,
                        businessVerificationStatus: 1,
                      },
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={(values, { resetForm }) => {
                      console.log("values::: ", values);
                      const formData = new FormData();
                      formData.append("file", values.file);
                      formData.append(
                        "residenceVerificationStatus",
                        values.residenceVerificationStatus
                      );
                      formData.append(
                        "collateralVerificationStatus",
                        values.collateralVerificationStatus
                      );
                      formData.append(
                        "businessVerificationStatus",
                        values.businessVerificationStatus
                      );
                      if (role === "Engineer") {
                        formData.append("bankerId", values.clientId);
                      }

                      setIsLoader(true);

                      axios
                        .request(`${APIURL}/pv/api/addCaseDetails`, {
                          method: "POST",
                          data: formData,
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((response) => {
                          console.log("response::", response);
                          setIsLoader(false);
                          resetForm({
                            values: {
                              clientId: "",
                              file: null,
                            },
                          });
                          setSelectValue("");
                          toast.success(response?.data);
                          fileInputRef.current.value = null;
                        })
                        .catch((error) => {
                          console.log("error", error);
                          setIsLoader(false);
                          toast.warning("Invalid...");
                          fileInputRef.current.value = null;
                        });
                    }}
                  >
                    {({
                      touched,
                      errors,
                      setFieldValue,
                      handleSubmit,
                      values,
                    }) => (
                      <>
                        <h1 className="text-center my-3">Upload Data</h1>
                        <Form className="pt-4" onSubmit={handleSubmit}>
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-lg-9 col-md-9 col-sm-12">
                              <div className="form-group">
                                {role === "Engineer" && (
                                  <div className="mt-2">
                                    <label htmlFor="clientId">
                                      Select Client*
                                    </label>
                                    <Form.Select
                                      id="clientId"
                                      name="clientId"
                                      options={clientDetails}
                                      value={selectValue}
                                      type="number"
                                      className={`form-control ${
                                        touched.clientId && errors.clientId
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={(e) => {
                                        setSelectValue(e.target.value);
                                        setFieldValue(
                                          "clientId",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="">Select Client</option>
                                      {clientDetails.map((ids) => (
                                        <option value={ids.id}>
                                          {ids.name}
                                        </option>
                                      ))}
                                    </Form.Select>

                                    <ErrorMessage
                                      component="div"
                                      name="clientId"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                <label htmlFor="file">Upload File</label>
                                <input
                                  id="file"
                                  name="file"
                                  type="file"
                                  accept=".xlsx, .xls"
                                  ref={fileInputRef}
                                  className={`form-control ${
                                    touched.file && errors.file
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "file",
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="file"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12  d-flex justify-content-center">
                              <a
                                href={CaseDetailsSample}
                                style={{
                                  width: "200px",
                                  backgroundColor: "#02CCFE",
                                  color: "#000",
                                }}
                                className="btn btn-info mt-3"
                              >
                                Download Demo File
                              </a>
                            </div>
                          </div>
                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.residenceVerificationStatus &&
                                errors.checkboxes?.residenceVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.residenceVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.residenceVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.residenceVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.residenceVerificationStatus"
                            >
                              RESIDENCE VERIFICATION
                            </label>
                          </div>

                          {/* Collateral Verification Checkbox */}
                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.collateralVerificationStatus &&
                                errors.checkboxes?.collateralVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.collateralVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.collateralVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.collateralVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.collateralVerificationStatus"
                            >
                              COLLATERAL VERIFICATION
                            </label>
                          </div>

                          {/* Business Verification Checkbox */}
                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.businessVerificationStatus &&
                                errors.checkboxes?.businessVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.businessVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.businessVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.businessVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.businessVerificationStatus"
                            >
                              BUSINESS VERIFICATION
                            </label>
                          </div>
                          <div className="" style={{ color: "#DC3545" }}>
                            {" "}
                            {errors.checkboxes}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-info my-3"
                            style={{
                              backgroundColor: "#02CCFE",
                              width: "auto",
                              color: "#000",
                            }}
                          >
                            {isLoader ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </Form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <Formik
                      initialValues={{
                        name: "",
                        mobile: "",
                        clientId: "",
                        address: "",
                        caseRefNo: "",
                        checkboxes: {
                          residenceVerificationStatus: 1,
                          collateralVerificationStatus: 1,
                          businessVerificationStatus: 1,
                        },
                      }}
                      validationSchema={UploadSchema}
                      onSubmit={(values, { resetForm }) => {
                        const formData = {
                          name: values.name,
                          mobile: values.mobile,
                          residenceVerificationStatus:
                            values.checkboxes.residenceVerificationStatus,
                          collateralVerificationStatus:
                            values.checkboxes.collateralVerificationStatus,
                          businessVerificationStatus:
                            values.checkboxes.businessVerificationStatus,
                          ...(role === "Engineer" && {
                            bankerId: values.clientId,
                          }),
                          address: values.address,
                          caseReferenceNumber: values.caseRefNo,
                        };
                        console.log("formData::: ", formData);

                        setIsLoader(true);
                        axios
                          .request(`${APIURL}/api/caseDetailFormDataModel`, {
                            method: "POST",
                            data: formData,
                            headers: {
                              Authorization: `Bearer ${token}`,
                              "Content-Type": "application/json",
                            },
                          })
                          .then(async (response) => {
                            SetWorkSpace(response?.data);
                            toast.success(response?.data);
                            setIsLoader(false);
                            SetShowUpload(true);
                            resetForm({
                              values: {
                                name: "",
                                mobile: "",
                                clientId: "",
                                address: "",
                                caseRefNo: "",
                              },
                            });
                            setSelectValue("");
                          })
                          .catch((error) => {
                            console.log(error);
                            setIsLoader(false);
                          });
                      }}
                    >
                      {({
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue,
                        values,
                      }) => (
                        <Form className="pt-4" onSubmit={handleSubmit}>
                          {role === "Engineer" && (
                            <div className="mt-2">
                              <label htmlFor="clientId">Select Client*</label>
                              <Form.Select
                                id="clientId"
                                name="clientId"
                                value={selectValue}
                                type="number"
                                className={`form-control ${
                                  touched.clientId && errors.clientId
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onChange={(e) => {
                                  setSelectValue(e.target.value);
                                  setFieldValue("clientId", e.target.value);
                                }}
                              >
                                <option value="">Select Client</option>
                                {clientDetails.map((ids) => (
                                  <option value={ids.id}>{ids.name}</option>
                                ))}
                              </Form.Select>

                              <ErrorMessage
                                component="div"
                                name="clientId"
                                className="invalid-feedback"
                              />
                            </div>
                          )}
                          <div>
                            <label>Name*</label>
                            <Field
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              className={`form-control ${
                                touched.name && errors.name ? "is-invalid" : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mt-2">
                            <label>Mobile*</label>
                            <Field
                              type="number"
                              name="mobile"
                              placeholder="Enter mobile"
                              className={`form-control ${
                                touched.mobile && errors.mobile
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="mobile"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mt-2">
                            <label>CaseRefNo*</label>
                            <Field
                              type="number"
                              name="caseRefNo"
                              placeholder="Enter caseRefNo"
                              className={`form-control ${
                                touched.caseRefNo && errors.caseRefNo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="caseRefNo"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-check mt-2">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.residenceVerificationStatus &&
                                errors.checkboxes?.residenceVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.residenceVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.residenceVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.residenceVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.residenceVerificationStatus"
                            >
                              RESIDENCE VERIFICATION
                            </label>
                          </div>

                          {/* Collateral Verification Checkbox */}
                          <div className="form-check mt-2">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.collateralVerificationStatus &&
                                errors.checkboxes?.collateralVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.collateralVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.collateralVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.collateralVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.collateralVerificationStatus"
                            >
                              COLLATERAL VERIFICATION
                            </label>
                          </div>

                          {/* Business Verification Checkbox */}
                          <div className="form-check mt-2">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.businessVerificationStatus &&
                                errors.checkboxes?.businessVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.businessVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.businessVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.businessVerificationStatus",
                                  e.target.checked ? 0 : 1
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.businessVerificationStatus"
                            >
                              BUSINESS VERIFICATION
                            </label>
                          </div>
                          <div className="mt-1" style={{ color: "#DC3545" }}>
                            {" "}
                            {errors.checkboxes}
                          </div>

                          <div className="mt-2">
                            <label>Address*</label>
                            <Field
                              as="textarea"
                              name="address"
                              placeholder="Enter Address"
                              className={`form-control ${
                                touched.address && errors.address
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="address"
                              className="invalid-feedback"
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn mt-3"
                            style={{ backgroundColor: "#02CCFE" }}
                          >
                            {isLoader ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          )}
          {listItem === "Pending" && <PendingPage />}
          {listItem === "Approved" && <Approved />}
          {listItem === "Rejected" && <Rejected />}
          {listItem === "All" && <All />}
        </div>
      </div>
    </>
  );
}
