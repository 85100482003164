import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Pagination from "../../Pagination/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";

function Leads() {
  const [searchInput, setSearchInput] = useState("");
  const [keyName, setKeyName] = useState("mobile");
  const [activeTab, setActiveTab] = useState("home");
  const [isLoader, setIsLoader] = useState(false);
  const [leadsEdit, SetLeadsEdit] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [leads, SetLeads] = useState([]);
  const tableLeads = React.useRef(null);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const [alert, setAlert] = useState(null);

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "home") {
      Getlead();
    }
  }, [activeTab]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  
  const searchHandler = () => {
    const search = { [keyName]: searchInput };
    console.log("search", search);
  };



  const LoginSchema = Yup.object().shape({
    corporateName: Yup.string().required("required"),
    empname: Yup.string().required("required"),
    mobile: Yup.string()
      .required("required")
      .min(10, "MobileNo must be 10 characters at minimum")
      .max(10, "MobileNo must be 10 characters at maximum"),
    noofemployees: Yup.string().required("required"),
  });

  const AddLeadsSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("required")
      .min(10, "MobileNo must be 10 characters at minimum"),
    empname: Yup.string().required("required"),
    corporateName: Yup.string().required("required"),
    noOfEmployees: Yup.string().required("required"),
    emailId: Yup.string().email("Invalid email format").required("required"),
  });
  const Getlead = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/corporate/getlead`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        SetLeads(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const pop = (e) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setAlert(null);
          deleteLead(e);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };
  const deleteLead = (e) => {
    console.log("emailid", e);
    const data = { emailid: e };
    setIsLoader(true);
    axios
      .request(`${APIURL}/corporate/deletelead`, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response:::::::", response);
        toast.success(response?.data);
        setIsLoader(false);
        Getlead();
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link  tab-item ${
                activeTab === "home" ? "active" : ""
              }`}
              id="home-tab"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected={activeTab === "home"}
              onClick={() => handleTabClick("home")}
            >
              Leads
            </button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button
              className={`nav-link  tab-item ${
                activeTab === "profile" ? "active" : ""
              }`}
              id="profile-tab"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected={activeTab === "profile"}
              onClick={() => handleTabClick("profile")}
            >
              Add Lead
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link  tab-item ${
                activeTab === "contact" ? "active" : ""
              }`}
              id="contact-tab"
              data-bs-toggle="tab"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected={activeTab === "contact"}
              onClick={() => handleTabClick("contact")}
            >
              Cost info and next steps
            </button>
          </li> */}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${
              activeTab === "home" ? "show active" : ""
            }`}
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {alert}
            <div className="pt-3">
              <div className="card">
                <div className="p-3">
                  {leadsEdit ? (
                    <>
                      <div className="d-flex justify-content-end mb-3 ">
                        <button
                          className="btn btn-secondary"
                          onClick={() => SetLeadsEdit(false)}
                          style={{ width: "10%" }}
                        >
                          Back
                        </button>
                      </div>
                      <Formik
                        initialValues={{
                          corporateName: editValue?.corporateName || "",
                          empname: editValue?.empname || "",
                          mobile: editValue?.mobile || "",
                          noofemployees: editValue?.noofemployees || "",
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={(values, { resetForm }) => {
                          console.log("values", values);
                          const data = {
                            ...values,
                            emailId: editValue.emailId,
                          };
                          setIsLoader(true);
                          axios
                            .request(`${APIURL}/corporate/updatedetails`, {
                              method: "POST",
                              data: data,
                              headers: {
                                "Content-Type": "application/json",
                              },
                            })
                            .then((response) => {
                              console.log("response", response);
                              setIsLoader(false);
                              SetLeadsEdit(false);
                              toast.success("Updated Successfully.");
                              Getlead();
                              resetForm();
                            })
                            .catch((error) => {
                              setIsLoader(false);
                              console.log(error);
                              resetForm();
                            });
                        }}
                      >
                        {({ touched, errors }) => (
                          <>
                            <Form className="">
                              <div>
                                <div>
                                  <div className="mt-2">
                                    <label>Employer name*</label>
                                    <Field
                                      type="text"
                                      name="corporateName"
                                      placeholder="Enter employer name"
                                      className={`form-control
                          ${
                            touched.corporateName && errors.corporateName
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="corporateName"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="mt-2">
                                    <label>Contact person name*</label>
                                    <Field
                                      type="text"
                                      name="empname"
                                      placeholder="Enter contact person name"
                                      className={`form-control
                          ${
                            touched.empname && errors.empname
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="empname"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <label>Mobile no*</label>
                                    <Field
                                      type="number"
                                      name="mobile"
                                      placeholder="Enter mobile no"
                                      className={`form-control
                          ${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="mobile"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <div className="mt-2">
                                    <label>No. of employees*</label>
                                    <Field
                                      type="number"
                                      name="noofemployees"
                                      placeholder="Enter no of employees"
                                      className={`form-control
                          ${
                            touched.noofemployees && errors.noofemployees
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="noofemployees"
                                      className="invalid-feedback"
                                    />
                                  </div>

                                  <button
                                    className="btn btn-success mt-3 border"
                                    type="submit"
                                    style={{ backgroundColor: "#66a725" }}
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Submit"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </>
                  ) : isLoader ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <DownloadTableExcel
                          filename="Leads"
                          sheet="users"
                          currentTableRef={tableLeads.current}
                        >
                          <button
                            className="btn btn-success mb-3 mb-md-0"
                            style={{
                              width: "120px",
                              backgroundColor: "#66a725",
                            }}
                          >
                            Download
                          </button>
                        </DownloadTableExcel>
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          {/* <select
                        className="form-select mx-md-2 mb-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setKeyName(e.target.value)}
                        value={keyName}
                      >
                        <option value="mobile">Mobile No.</option>
                        <option value="lid">Loan id</option>
                        <option value="name">Name</option>
                      </select> */}
                          <input
                            className="form-control"
                            style={{
                              height: "38px",
                              width: "100%",
                              background: "#fff",
                            }}
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleInputChange}
                          />
                          <button
                            className="btn btn-success ml-md-2 mt-2 mt-md-0"
                            style={{ background: "#66a725", color: "#fff" }}
                            onClick={searchHandler}
                          >
                            {isLoader ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Search"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive mt-3">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="text-nowrap">Sr. No</th>
                              <th className="text-nowrap">Lead recd Date </th>
                              <th className="text-nowrap">Time</th>
                              {/* <th className="text-nowrap">Lead From</th> */}
                              <th className="text-nowrap">Email</th>
                              {/*  <th className="text-nowrap">Employer name</th>
                              <th className="text-nowrap">Contact name</th>
                              <th className="text-nowrap">Mobile</th>
                              <th className="text-nowrap">No of employees</th>
                              <th className="text-nowrap">
                                Set up (Custom HR)
                              </th>
                              <th className="text-nowrap">Funding</th>
                             <th className="text-nowrap">Type</th>
                             
                              <th
                                className="text-nowrap"
                                style={{ color: "red" }}
                              >
                                Lead marked Closed (no good to us)
                              </th>
                              <th
                                className="text-nowrap"
                                style={{ color: "orange" }}
                              >
                                Lead marked as Non Assured
                              </th>
                              <th
                                className="text-nowrap"
                                style={{ color: "green" }}
                              >
                                Lead marked as Assured Category
                              </th>
                              <th className="text-nowrap">Status</th>
                              <th className="text-nowrap">Remark</th> */}
                              <th
                                className="text-nowrap"
                                style={{ width: "100px" }}
                              >
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {leads
                              ?.slice(pagination.start, pagination.end)
                              ?.map((item, i) => (
                                <tr key={i}>
                                  <td>{i + 1 + pagination.start}</td>
                                  <td className="text-nowrap">
                                    {moment(item.leadRecordDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-nowrap">
                                    {moment(item.time).format("HH:mm:ss")}
                                  </td>
                                  {/* <td className="text-nowrap">
                                    {item.leadFrom}
                                  </td>{" "} */}
                                  <td
                                    className="text-nowrap"
                                    onClick={() => {
                                      setEditValue(item);
                                      SetLeadsEdit(true);
                                    }}
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    {item.emailId}
                                  </td>
                                  {/* <td className="text-nowrap">
                                    {item.corporateName}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.empname}
                                  </td>
                                  <td className="text-nowrap">{item.mobile}</td>
                                  <td className="text-nowrap">
                                    {item.noofemployees}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.customHr}
                                  </td>
                                  <td className="text-nowrap">
                                    {item.funding}
                                  </td>
                                   <td className="text-nowrap">{item.type}</td>
                                  <td className="text-nowrap"></td>
                                  <td className="text-nowrap"></td>
                                  <td className="text-nowrap">
                                    {item.leadMarkedCategory}
                                  </td>
                                  <td className="text-nowrap">{item.status}</td>

                                  <td className="text-nowrap">
                                    {item.remarks}
                                  </td> */}
                                  <td className="text-nowrap">
                                    <button
                                      className="btn btn-danger"
                                      style={{ width: "100px" }}
                                      onClick={() => {
                                        pop(item?.emailId);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {leads && leads.length !== 0 && (
                        <Pagination
                          showPrePage={showPrePage}
                          onPaginationChange={onPaginationChange}
                          total={leads.length}
                        />
                      )}
                      <table
                        className="table table-striped mt-3 "
                        style={{ display: "none" }}
                        ref={tableLeads}
                      >
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">Lead recd Date </th>
                            <th className="text-nowrap">Time</th>
                            <th className="text-nowrap">Email</th>
                            <th className="text-nowrap">Lead From</th>
                            <th className="text-nowrap">Employer name</th>
                            <th className="text-nowrap">Contact person name</th>
                            <th className="text-nowrap">No of employees</th>
                            <th className="text-nowrap">Type</th>
                            <th className="text-nowrap">Set up (Custom HR)</th>
                            <th className="text-nowrap">Funding</th>
                            <th
                              className="text-nowrap"
                              style={{ color: "red" }}
                            >
                              Lead marked Closed (no good to us)
                            </th>
                            <th
                              className="text-nowrap"
                              style={{ color: "orange" }}
                            >
                              Lead marked as Non Assured
                            </th>
                            <th
                              className="text-nowrap"
                              style={{ color: "green" }}
                            >
                              Lead marked as Assured Category
                            </th>
                            <th className="text-nowrap">Status</th>
                            <th className="text-nowrap">Remark</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leads?.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-nowrap">
                                {moment(item.leadRecordDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td className="text-nowrap">
                                {moment(item.time).format("hh:mm:ss")}
                              </td>
                              <td className="text-nowrap">{item.emailId}</td>

                              <td className="text-nowrap">{item.leadFrom}</td>
                              <td className="text-nowrap">
                                {item.corporateName}
                              </td>
                              <td className="text-nowrap">{item.empname}</td>
                              <td className="text-nowrap">
                                {item.noofemployees}
                              </td>
                              <td className="text-nowrap">{item.type}</td>
                              <td className="text-nowrap">{item.customHr}</td>
                              <td className="text-nowrap">{item.funding}</td>
                              <td className="text-nowrap"></td>
                              <td className="text-nowrap"></td>
                              <td className="text-nowrap">
                                {item.leadMarkedCategory}
                              </td>
                              <td className="text-nowrap">{item.status}</td>

                              <td className="text-nowrap">{item.remarks}</td>
                              <td className="text-nowrap">
                                <button
                                  className="btn btn-success"
                                  onClick={() => {
                                    SetLeadsEdit(true);
                                    setEditValue(item);
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className={`tab-pane fade ${
              activeTab === "profile" ? "show active" : ""
            }`}
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3">
                  <Formik
                    initialValues={{
                      empname: "",
                      emailId: "",
                      corporateName: "",
                      noOfEmployees: "",
                      mobile: "",
                    }}
                    validationSchema={AddLeadsSchema}
                    onSubmit={(values, { resetForm }) => {
                      setIsLoader(true);
                      axios
                        .request(`${APIURL}/corporate/addlead`, {
                          method: "POST",
                          data: values,
                          headers: {
                            "Content-Type": "application/json",
                          },
                        })
                        .then((response) => {
                          console.log("response:::", response);
                          setIsLoader(false);
                          toast.success("Successfully...");
                          resetForm();
                        })
                        .catch((error) => {
                          console.log("error", error);
                          setIsLoader(false);
                          toast.warning("User not exist...");
                          resetForm();
                        });
                    }}
                  >
                    {({ touched, errors }) => (
                      <>
                        <Form className="">
                          <div>
                            <div className="">
                              <label>Work Email*</label>
                              <Field
                                type="email"
                                name="emailId"
                                placeholder="Enter work email"
                                className={`form-control
                      ${touched.emailId && errors.emailId ? "is-invalid" : ""}`}
                              />
                              <ErrorMessage
                                component="div"
                                name="emailId"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mt-2">
                              <label>Employer Name*</label>
                              <Field
                                type="text"
                                name="corporateName"
                                placeholder="Enter employer name"
                                className={`form-control
                      ${
                        touched.corporateName && errors.corporateName
                          ? "is-invalid"
                          : ""
                      }`}
                              />
                              <ErrorMessage
                                component="div"
                                name="corporateName"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mt-2">
                              <label>Your Name*</label>
                              <Field
                                type="text"
                                name="empname"
                                placeholder="Enter name"
                                className={`form-control
                      ${touched.empname && errors.empname ? "is-invalid" : ""}`}
                              />
                              <ErrorMessage
                                component="div"
                                name="empname"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mt-2">
                              <label>Mobile No*</label>
                              <Field
                                type="number"
                                name="mobile"
                                placeholder="Enter mobile no"
                                className={`form-control
                      ${touched.mobile && errors.mobile ? "is-invalid" : ""}`}
                              />
                              <ErrorMessage
                                component="div"
                                name="mobile"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mt-2">
                              <label>No. of employees*</label>
                              <Field
                                type="number"
                                name="noOfEmployees"
                                placeholder="Enter no. of employees"
                                className={`form-control
                      ${
                        touched.noOfEmployees && errors.noOfEmployees
                          ? "is-invalid"
                          : ""
                      }`}
                              />
                              <ErrorMessage
                                component="div"
                                name="noOfEmployees"
                                className="invalid-feedback"
                              />
                            </div>

                            <button
                              className="btn btn-success mt-3 border"
                              type="submit"
                              style={{ backgroundColor: "#66a725" }}
                            >
                              {isLoader ? (
                                <div
                                  className="spinner-border text-success"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "contact" ? "show active" : ""
            }`}
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="pt-3">
              <div className="card" style={{ width: "75%" }}>
                <div className="p-3">
                  <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                    1. Salarytap is free for the Employer
                  </h6>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    2. Salarytap charges just Rs.300 from user as set up fees
                    that also on first withdrawal
                  </h6>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    3. Financier cost to Employee is expected to be a maximum of
                    14-18% pa (charged as a flat convenience fee on each
                    withdrawal)
                  </h6>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    4. Customisation cost is between Rs.3-5 L. But, final cost
                    will be decided by the Infotech team
                  </h6>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    Next steps: Create a new workspace or login using your
                    registered email and complete your workspace and upload
                    employees
                  </h6>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Leads;
