import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { APIURL } from "../../../Config";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Pagination from "../../Pagination/Pagination";

function Funnel() {
  const [activeTab, setActiveTab] = useState("home");
  const [funnel, SetFunnel] = useState([]);
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const [selectedMode, setSelectedMode] = useState("all");
  const [selectDate, setSelectDate] = useState(false);
  const [dateValue1, setDateValue1] = useState("");
  const [dateValue2, setDateValue2] = useState("");
  const [row, setRow] = useState([]);
  const tableRef = useRef(null);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonth("0");
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "home") {
      setSelectedMode("all");
      getAllfunnelreport();
      const todayDate = new Date();
      setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
      const sevenDaysBeforeDate = new Date();
      sevenDaysBeforeDate.setDate(todayDate.getDate() - 7);
      setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    }
    if (activeTab === "dum") {
      getRejectionReasonscsa();
      setSelectDate(false);
      const todayDate = new Date();
      setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
      const sevenDaysBeforeDate = new Date();
      sevenDaysBeforeDate.setDate(todayDate.getDate() - 7);
      setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    }
  }, [activeTab]);

  // const handleButtonClick = (mode) => {
  //   setSelectedMode(mode);

  // };

  const handleButtonClick = (mode) => {
    setSelectedMode(mode);
    if (mode === "all") {
      getAllfunnelreport();
    } else {
      getfunnelreport(mode);
    }
  };
  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
  };

  const getfunnelreport = (item) => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/getfunnelreport/${item}/${month}`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        if (response?.data !== "") {
          setData(response?.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const getAllfunnelreport = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/getfunnelreport/all`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setData(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const getfunnelreportcount = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/getfunnelreportcount`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        SetFunnel(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const handleSubmit = () => {
    getRejectionReasonscsa();
  };

  const getRejectionReasonscsa = () => {
    setIsLoader(true);
    axios
      .request(
        `${APIURL}/rejectionReasonscsa/${dateValue1}/${dateValue2}/ALL`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setRow(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link tab-item ${
                activeTab === "home" ? "active" : ""
              }`}
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected={activeTab === "home"}
              onClick={() => handleTabClick("home")}
            >
              Funnel
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link tab-item ${
                activeTab === "dum" ? "active" : ""
              }`}
              id="dum-tab"
              data-bs-toggle="tab"
              data-bs-target="#dum"
              type="button"
              role="tab"
              aria-controls="dum"
              aria-selected={activeTab === "dum"}
              onClick={() => handleTabClick("dum")}
            >
              Login Dump
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  {/* <div className="table-responsive">
                    <div
                      className="py-1"
                      style={{ backgroundColor: "#a6df6d" }}
                    >
                      <h5 className="text-center m-0">Funnel Summery</h5>
                    </div>
                    <table className="table table-striped mt-1">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Month/Year</th>
                          <th>Mobile no. registration</th>
                          <th>Application form completed</th>
                          <th>Email authentication</th>
                          <th>Provide Bank Statement</th>
                          <th>TnC signed</th>
                          <th>Confirm bank details</th>
                          <th>Disbursed </th>
                          <th>Accept offer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {funnel?.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item?.month_year}</td>
                            <td
                              style={{
                                color:
                                  item?.mobile_no_registration === 0
                                    ? ""
                                    : "blue",
                                cursor:
                                  item?.mobile_no_registration === 0
                                    ? ""
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (item.mobile_no_registration !== 0) {
                                  getfunnelreport(
                                    "mobile_no_registration",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.mobile_no_registration}
                            </td>
                            <td
                              style={{
                                color:
                                  item?.application_form_completed === 0
                                    ? ""
                                    : "blue",
                                cursor:
                                  item?.application_form_completed === 0
                                    ? ""
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (item.application_form_completed !== 0) {
                                  getfunnelreport(
                                    "application_form_completed",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.application_form_completed}
                            </td>
                            <td
                              style={{
                                color:
                                  item?.email_authentication === 0
                                    ? ""
                                    : "blue",
                                cursor:
                                  item?.email_authentication === 0
                                    ? ""
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (item.email_authentication !== 0) {
                                  getfunnelreport(
                                    "email_authentication",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.email_authentication}
                            </td>
                            <td
                              style={{
                                color:
                                  item?.provide_bank_statement === 0
                                    ? ""
                                    : "blue",
                                cursor:
                                  item?.provide_bank_statement === 0
                                    ? ""
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (item.provide_bank_statement !== 0) {
                                  getfunnelreport(
                                    "provide_bank_statement",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.provide_bank_statement}
                            </td>
                            <td
                              style={{
                                color: item?.tnc_signed === 0 ? "" : "blue",
                                cursor: item?.tnc_signed === 0 ? "" : "pointer",
                              }}
                              onClick={() => {
                                if (item.tnc_signed !== 0) {
                                  getfunnelreport(
                                    "tnc_signed",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.tnc_signed}
                            </td>
                            <td
                              style={{
                                color:
                                  item?.confirm_bank_details === 0
                                    ? ""
                                    : "blue",
                                cursor:
                                  item?.confirm_bank_details === 0
                                    ? ""
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (item.confirm_bank_details !== 0) {
                                  getfunnelreport(
                                    "confirm_bank_details",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.confirm_bank_details}
                            </td>
                            <td
                              style={{
                                color: item?.disbursed === 0 ? "" : "blue",
                                cursor: item?.disbursed === 0 ? "" : "pointer",
                              }}
                              onClick={() => {
                                if (item.disbursed !== 0) {
                                  getfunnelreport(
                                    "disbursed",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.disbursed}
                            </td>
                            <td
                              style={{
                                color: item?.accept_offer === 0 ? "" : "blue",
                                cursor:
                                  item?.accept_offer === 0 ? "" : "pointer",
                              }}
                              onClick={() => {
                                if (item.accept_offer !== 0) {
                                  getfunnelreport(
                                    "accept_offer",
                                    item?.month_year
                                  );
                                  setShow(true);
                                }
                              }}
                            >
                              {item?.accept_offer}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>  */}

                  {/* <div className="d-flex">
                    <button
                      className={`${getButtonClass("all")} mx-1`}
                      onClick={() => {
                        setSelectedMode("all");
                        getAllfunnelreport();
                      }}
                    >
                      All
                    </button>
                    <button
                      className={`${getButtonClass(
                        "mobile_no_registration"
                      )} mx-1`}
                      onClick={() =>
                        handleButtonClick("mobile_no_registration")
                      }
                    >
                      Mobile no. registration
                    </button>
                    <button
                      className={`${getButtonClass(
                        "application_form_completed"
                      )} mx-1`}
                      onClick={() =>
                        handleButtonClick("application_form_completed")
                      }
                    >
                      Application form completed
                    </button>
                    <button
                      className={`${getButtonClass(
                        "email_authentication"
                      )} mx-1`}
                      onClick={() => handleButtonClick("email_authentication")}
                    >
                      Email authentication
                    </button>
                    <button
                      className={`${getButtonClass(
                        "provide_bank_statement"
                      )} mx-1`}
                      onClick={() =>
                        handleButtonClick("provide_bank_statement")
                      }
                    >
                      Provide Bank Statement
                    </button>
                    <button
                      className={`${getButtonClass("tnc_signed")} mx-1`}
                      onClick={() => handleButtonClick("tnc_signed")}
                    >
                      TnC signed
                    </button>
                    <button
                      className={`${getButtonClass(
                        "confirm_bank_details"
                      )} mx-1`}
                      onClick={() => handleButtonClick("confirm_bank_details")}
                    >
                      Confirm bank details
                    </button>
                    <button
                      className={`${getButtonClass("disbursed")} mx-1`}
                      onClick={() => handleButtonClick("disbursed")}
                    >
                      Disbursed
                    </button>
                  </div> */}

                  <div className="mt-3">
                    <ul
                      className="nav nav-tabs funnelTab"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "all" ? "active" : ""
                          }`}
                          role="tab"
                          onClick={() => handleButtonClick("all")}
                        >
                          All
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "mobile_no_registration"
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                          onClick={() =>
                            handleButtonClick("mobile_no_registration")
                          }
                        >
                          Mobile no. registration
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "application_form_completed"
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                          onClick={() =>
                            handleButtonClick("application_form_completed")
                          }
                        >
                          Application form completed
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "email_authentication"
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                          onClick={() =>
                            handleButtonClick("email_authentication")
                          }
                        >
                          Email authentication
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "provide_bank_statement"
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                          onClick={() =>
                            handleButtonClick("provide_bank_statement")
                          }
                        >
                          Provide Bank Statement
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "tnc_signed" ? "active" : ""
                          }`}
                          role="tab"
                          onClick={() => handleButtonClick("tnc_signed")}
                        >
                          TnC signed
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "confirm_bank_details"
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                          onClick={() =>
                            handleButtonClick("confirm_bank_details")
                          }
                        >
                          Confirm bank details
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "disbursed" ? "active" : ""
                          }`}
                          role="tab"
                          onClick={() => handleButtonClick("disbursed")}
                        >
                          Disbursed
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${
                            selectedMode === "rejectreason" ? "active" : ""
                          }`}
                          role="tab"
                          onClick={() => handleButtonClick("rejectreason")}
                        >
                          Reject Reason
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className="d-flex justify-content-end mt-3">
                      <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center justify-content-end">
                        <div>
                          <select
                            className="form-select mx-md-2 mb-md-0"
                            id="lang"
                            onChange={handleMonthChange}
                            value={month}
                          >
                            {months.map(({ name, value }) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ml-md-2">
                          <button
                            className="btn btn-success border"
                            style={{ background: "#66a725", color: "#fff" }}
                            onClick={() => getfunnelreport(selectedMode, month)}
                          >
                            {isLoading ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            ) : (
                              "Search"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Entry dt</th>
                            <th>Entry time</th>
                            <th>App id</th>
                            <th>Employer Name</th>
                            <th>Customer Name </th>
                            <th>Type</th>

                            <th>Mobile no. registration </th>
                            <th>Application form completed</th>

                            <th>Email authentication</th>
                            <th>Provide Bank Statement</th>
                            <th>TnC signed</th>
                            <th>Confirm bank details</th>
                            <th>Disbursement date and time</th>
                            <th>Reject Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoader ? (
                            <>
                              {data?.map((item, i) => (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>
                                    {item?.entryDate
                                      ? moment(item.entryDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {item?.entryTime
                                      ? moment(item.entryTime).format(
                                          "HH:mm:ss"
                                        )
                                      : ""}
                                  </td>
                                  <td>{item?.appclientId}</td>
                                  <td>{item?.clientName}</td>
                                  <td>{item?.employerName}</td>
                                  <td>{item?.typeofuser}</td>

                                  <td
                                    style={{
                                      color:
                                        item?.mobilenoregistration === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.mobilenoregistration}
                                  </td>

                                  <td
                                    style={{
                                      color:
                                        item?.applicationformcompleted ===
                                        "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.applicationformcompleted}
                                  </td>

                                  <td
                                    style={{
                                      color:
                                        item?.emailAuthentication === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.emailAuthentication}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        item?.providebankdetails === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.providebankdetails}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        item?.tncsigned === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.tncsigned}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        item?.confirmbankdetails === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.confirmbankdetails}
                                  </td>
                                  <td
                                    style={{
                                      color:
                                        item?.disbursementdate === "Done"
                                          ? "#66a725"
                                          : "",
                                    }}
                                  >
                                    {item?.disbursementdate}
                                  </td>
                                  <td>{item?.rejectreason}</td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                      <div className="my-3 d-flex justify-content-center">
                        {isLoader && (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="dum"
            role="tabpanel"
            aria-labelledby="dum-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      <DownloadTableExcel
                        filename="Login Dump"
                        sheet="users"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          className="btn btn-success mb-3 mb-md-0"
                          style={{ width: "120px", background: "#66a725" }}
                        >
                          Download
                        </button>
                      </DownloadTableExcel>
                    </div>
                    <div>
                      {!selectDate ? (
                        <button
                          className="btn btn-info"
                          style={{ width: "150px" }}
                          onClick={() => setSelectDate(true)}
                        >
                          Select Date
                        </button>
                      ) : (
                        <>
                          <div className="d-flex" style={{ width: "500px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <div>
                                <DatePicker
                                  className="ml-2"
                                  label="To Date"
                                  value={dateValue1}
                                  inputFormat="dd-MM-yyyy"
                                  onChange={(e) =>
                                    setDateValue1(
                                      moment(e).format("YYYY-MM-DD")
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </div>
                              <div>
                                <DatePicker
                                  className="ml-2"
                                  label="From Date"
                                  value={dateValue2}
                                  inputFormat="dd-MM-yyyy"
                                  onChange={(e) =>
                                    setDateValue2(
                                      moment(e).format("YYYY-MM-DD")
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </div>
                            </LocalizationProvider>
                            <div className="mt-2 mx-2">
                              <button
                                className="btn btn-success"
                                style={{
                                  background: "#66a725",
                                  width: "100px",
                                }}
                                onClick={() => handleSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table class="table table-striped mt-3">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>S No.</th>
                          <th style={{ textAlign: "center" }}>EntryDate</th>
                          <th style={{ textAlign: "center" }}>ContactNo</th>
                          <th style={{ textAlign: "center" }}>Name</th>
                          <th style={{ textAlign: "center" }}>Employer Name</th>
                          <th style={{ textAlign: "center" }}>AadhaarId</th>
                          <th style={{ textAlign: "center" }}>PanCard</th>
                          <th style={{ textAlign: "center" }}>Email id</th>
                          <th style={{ textAlign: "center" }}>App client id</th>
                          <th style={{ textAlign: "center" }}>
                            Application Status
                          </th>
                          <th style={{ textAlign: "center" }}>
                            Rejected Reason
                          </th>
                          <th style={{ textAlign: "center" }}>Reject Status</th>
                          <th style={{ textAlign: "center" }}>App Status</th>
                          <th style={{ textAlign: "center" }}>PIN Code</th>
                          <th style={{ textAlign: "center" }}>District</th>
                          <th style={{ textAlign: "center" }}>State</th>
                          <th style={{ textAlign: "center" }}>
                            Client Date of Birth
                          </th>
                          <th style={{ textAlign: "center" }}>AadhaarName</th>
                          <th style={{ textAlign: "center" }}>
                            ContactAvailableSize
                          </th>
                          <th style={{ textAlign: "center" }}>
                            HalfCibilScore
                          </th>
                          <th style={{ textAlign: "center" }}>
                            AadhaarLinkStatus
                          </th>
                          <th>Aadhaar link with mobile</th>
                          <th style={{ textAlign: "center" }}>
                            SelfieFaceMatching
                          </th>
                          <th style={{ textAlign: "center" }}>
                            SelfieLiveStatus
                          </th>
                          <th style={{ textAlign: "center" }}>FirstSalary</th>
                          <th style={{ textAlign: "center" }}>
                            SecondMonthSalary
                          </th>
                          <th style={{ textAlign: "center" }}>
                            ThirdMonthSalary
                          </th>
                          <th style={{ textAlign: "center" }}>
                            AccountAggregatorAccountNo
                          </th>
                          <th style={{ textAlign: "center" }}>
                            FullCibilScore
                          </th>
                          <th style={{ textAlign: "center" }}>
                            BorrowerOpenAccount
                          </th>
                          <th style={{ textAlign: "center" }}>
                            RetailTotalAcc
                          </th>
                          <th style={{ textAlign: "center" }}>RecentAcc</th>
                          <th style={{ textAlign: "center" }}>OldestAcc</th>
                          <th style={{ textAlign: "center" }}>
                            OverAllTotalOutBalance
                          </th>
                          <th style={{ textAlign: "center" }}>
                            RetailTotalOutStanBalance
                          </th>
                          <th style={{ textAlign: "center" }}>
                            RetailTotalSanctionAmount
                          </th>
                          <th style={{ textAlign: "center" }}>DateOfJoining</th>
                          <th style={{ textAlign: "center" }}>
                            EmployerOrNotAnEmployer
                          </th>
                          <th style={{ textAlign: "center" }}>
                            EstablishmentName
                          </th>
                          <th style={{ textAlign: "center" }}>EpfClientName</th>
                          <th style={{ textAlign: "center" }}>
                            EpfTotalAmount
                          </th>
                          <th style={{ textAlign: "center" }}>
                            EpfEmployeesCount
                          </th>
                          <th style={{ textAlign: "center" }}>IsEmployed</th>
                          <th style={{ textAlign: "center" }}>BankAccountNo</th>
                          <th style={{ textAlign: "center" }}>IFSCCode</th>
                          <th style={{ textAlign: "center" }}>
                            ImpsBeneNameDest
                          </th>
                          <th style={{ textAlign: "center" }}>Isemployed</th>
                          <th style={{ textAlign: "center" }}>
                            BorrowerOpenAccount
                          </th>
                          <th style={{ textAlign: "center" }}>RecentAccount</th>
                          <th style={{ textAlign: "center" }}>OldestAccount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row &&
                          row
                            ?.slice(pagination.start, pagination.end)
                            ?.map((row, index) => (
                              <>
                                <tr key={index}>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {index + 1 + pagination.start}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {moment(row?.entrydate).format(
                                      "DD-MM-YYYY"
                                    ) || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.contactno || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.name || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.employername || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.aadhaarid || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.pancard || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.emailid || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.appclientid || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.applicationstatus || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.rejectedreason || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.rejectstatus || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.appstatus || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.pincode || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.district || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.state || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.clientdob
                                      ? moment(row?.clientdob).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.aadhaarname || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.contactavailablesize || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.halfcibilscore || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.aadhaarlinkstatus || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.resultcode || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.selfiefacematching || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.selfielivestatus || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.firstsalary || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.secondmonthsalary || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.thirdmonthsalary || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.accountaggregatoraccountno || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.fullcibilscore || "-"}
                                  </td>{" "}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.borrowerOpenAccount || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.retailTotalAcc || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.recentAcc || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.oldestAcc || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.overalltotaloutbal || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.retailtotoutstanbal || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.retailtotsanctionamunt || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.dateofjoining
                                      ? moment(row?.dateofjoining).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.employerornotanemployer || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.establishmentname || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.epfclientname || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.epftotalamount || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.epfemployeescount || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.isEmployed || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.bankaccountno || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.ifsccode || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.impsBeneNameDest || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.isemployed || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.borroweropenaccount || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.recentacc || "-"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row?.oldestacc || "-"}
                                  </td>
                                </tr>
                              </>
                            ))}
                      </tbody>
                    </table>
                  </div>
                  {row && row.length !== 0 && (
                    <Pagination
                      showPrePage={showPrePage}
                      onPaginationChange={onPaginationChange}
                      total={row.length}
                    />
                  )}
                  <table
                    className="table table-striped"
                    style={{ display: "none" }}
                    ref={tableRef}
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>S No.</th>
                        <th style={{ textAlign: "center" }}>EntryDate</th>
                        <th style={{ textAlign: "center" }}>ContactNo</th>
                        <th style={{ textAlign: "center" }}>Name</th>
                        <th style={{ textAlign: "center" }}>Employer Name</th>
                        <th style={{ textAlign: "center" }}>AadhaarId</th>
                        <th style={{ textAlign: "center" }}>PanCard</th>
                        <th style={{ textAlign: "center" }}>Email id</th>
                        <th style={{ textAlign: "center" }}>App client id</th>
                        <th style={{ textAlign: "center" }}>
                          Application Status
                        </th>
                        <th style={{ textAlign: "center" }}>Rejected Reason</th>
                        <th style={{ textAlign: "center" }}>Reject Status</th>
                        <th style={{ textAlign: "center" }}>App Status</th>
                        <th style={{ textAlign: "center" }}>PIN Code</th>
                        <th style={{ textAlign: "center" }}>District</th>
                        <th style={{ textAlign: "center" }}>State</th>
                        <th style={{ textAlign: "center" }}>
                          Client Date of Birth
                        </th>
                        <th style={{ textAlign: "center" }}>AadhaarName</th>
                        <th style={{ textAlign: "center" }}>
                          ContactAvailableSize
                        </th>
                        <th style={{ textAlign: "center" }}>HalfCibilScore</th>
                        <th style={{ textAlign: "center" }}>
                          AadhaarLinkStatus
                        </th>
                        <th>Aadhaar link with mobile</th>
                        <th style={{ textAlign: "center" }}>
                          SelfieFaceMatching
                        </th>
                        <th style={{ textAlign: "center" }}>
                          SelfieLiveStatus
                        </th>
                        <th style={{ textAlign: "center" }}>FirstSalary</th>
                        <th style={{ textAlign: "center" }}>
                          SecondMonthSalary
                        </th>
                        <th style={{ textAlign: "center" }}>
                          ThirdMonthSalary
                        </th>
                        <th style={{ textAlign: "center" }}>
                          AccountAggregatorAccountNo
                        </th>
                        <th style={{ textAlign: "center" }}>FullCibilScore</th>
                        <th style={{ textAlign: "center" }}>
                          BorrowerOpenAccount
                        </th>
                        <th style={{ textAlign: "center" }}>RetailTotalAcc</th>
                        <th style={{ textAlign: "center" }}>RecentAcc</th>
                        <th style={{ textAlign: "center" }}>OldestAcc</th>
                        <th style={{ textAlign: "center" }}>
                          OverAllTotalOutBalance
                        </th>
                        <th style={{ textAlign: "center" }}>
                          RetailTotalOutStanBalance
                        </th>
                        <th style={{ textAlign: "center" }}>
                          RetailTotalSanctionAmount
                        </th>
                        <th style={{ textAlign: "center" }}>DateOfJoining</th>
                        <th style={{ textAlign: "center" }}>
                          EmployerOrNotAnEmployer
                        </th>
                        <th style={{ textAlign: "center" }}>
                          EstablishmentName
                        </th>
                        <th style={{ textAlign: "center" }}>EpfClientName</th>
                        <th style={{ textAlign: "center" }}>EpfTotalAmount</th>
                        <th style={{ textAlign: "center" }}>
                          EpfEmployeesCount
                        </th>
                        <th style={{ textAlign: "center" }}>IsEmployed</th>
                        <th style={{ textAlign: "center" }}>BankAccountNo</th>
                        <th style={{ textAlign: "center" }}>IFSCCode</th>
                        <th style={{ textAlign: "center" }}>
                          ImpsBeneNameDest
                        </th>
                        <th style={{ textAlign: "center" }}>Isemployed</th>
                        <th style={{ textAlign: "center" }}>
                          BorrowerOpenAccount
                        </th>
                        <th style={{ textAlign: "center" }}>RecentAccount</th>
                        <th style={{ textAlign: "center" }}>OldestAccount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row &&
                        row.map((row, index) => (
                          <>
                            <tr key={index}>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {moment(row?.entrydate).format("DD-MM-YYYY") ||
                                  "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.contactno || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.name || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.employername || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.aadhaarid || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.pancard || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.emailid || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.appclientid || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.applicationstatus || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.rejectedreason || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.rejectstatus || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.appstatus || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.pincode || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.district || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.state || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.clientdob
                                  ? moment(row?.clientdob).format("DD-MM-YYYY")
                                  : "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.aadhaarname || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.contactavailablesize || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.halfcibilscore || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.aadhaarlinkstatus || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.resultcode || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.selfiefacematching || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.selfielivestatus || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.firstsalary || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.secondmonthsalary || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.thirdmonthsalary || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.accountaggregatoraccountno || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.fullcibilscore || "-"}
                              </td>{" "}
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.borrowerOpenAccount || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.retailTotalAcc || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.recentAcc || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.oldestAcc || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.overalltotaloutbal || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.retailtotoutstanbal || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.retailtotsanctionamunt || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.dateofjoining
                                  ? moment(row?.dateofjoining).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.employerornotanemployer || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.establishmentname || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.epfclientname || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.epftotalamount || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.epfemployeescount || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.isEmployed || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.bankaccountno || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.ifsccode || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.impsBeneNameDest || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.isemployed || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.borroweropenaccount || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.recentacc || "-"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  whiteSpace: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                {row?.oldestacc || "-"}
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Funnel;
