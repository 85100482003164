import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
export default function Pagination({ showPrePage, onPaginationChange, total }) {
  const [counter, setCounter] = useState(1);

  const [numberOfButton, setNumberOfButton] = useState();

  useEffect(() => {
    setCounter(1);
  }, [total]);
  useEffect(() => {
    const value = showPrePage * counter;
    // console.log("showPrePage::", showPrePage, total);
    // console.log("counter:::", counter, value);
    onPaginationChange(value - showPrePage, value);
    setNumberOfButton(Math.ceil(total / showPrePage));
  }, [counter, total]);
  const onButtonClick = (type) => {
    if (type === "p") {
      if (counter === 1) {
        setCounter(1);
      } else {
        setCounter(counter - 1);
      }
    } else if (type === "n") {
      if (numberOfButton === counter) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
    }
  };

  return (
    <>
      <div className="mt-4 d-flex justify-content-end mr-4">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => setCounter(1)}
                style={{ color: "#66a725" }}
              >
                <KeyboardDoubleArrowLeftIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => onButtonClick("p")}
                style={{ color: "#66a725" }}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: "15px" }} />
              </a>
            </li>

            {new Array(numberOfButton)?.fill("")?.map((item, index) => (
              <>
                <li
                  className={`page-item ${
                    index + 1 === counter ? "active" : null
                  }`}
                >
                  {(index + 1 === counter ||
                    index + 1 === counter - 1 ||
                    index + 1 === counter + 1) && (
                    <button
                      className="page-link"
                      onClick={() => setCounter(index + 1)}
                      // style={{ backgroundColor: "#66a725" }}
                      style={{
                        backgroundColor: `${
                          index + 1 === counter ? "#66a725" : "white"
                        }`,
                        color: `${index + 1 === counter ? "white" : "#66a725"}`,
                      }}
                    >
                      {index + 1}
                    </button>
                  )}
                </li>
              </>
            ))}
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => onButtonClick("n")}
                style={{ color: "#66a725" }}
              >
                <KeyboardArrowRightIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => setCounter(numberOfButton)}
                style={{ color: "#66a725" }}
              >
                <KeyboardDoubleArrowRightIcon style={{ fontSize: "15px" }} />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <style jsx>{`
        .page-item.active .page-link {
          border-color: #66a725;
        }
      `}</style>
    </>
  );
}
