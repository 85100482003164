import React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

function Pricing() {
  return (
    <>
      <Navbar />
      <div className="container mt-3 px-md-5">
        <div className="card bg-light ">
          <div
            className="card-header h4 text-center"
            style={{
              backgroundColor: "#66a725",
              color: "white",
              padding: 4,
            }}
          >
            Pricing
          </div>
          <div className="card-body p-4">
            <div>
              <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                Free Integration with Salarytap Platform
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                Integrating Salarytap directly with your existing systems is
                completely free of charge. We ensure a seamless and hassle-free
                setup process, enabling your employees to start benefiting from
                our service without any additional costs to your company.
              </h6>
              <h4 className=" mt-4" style={{ fontSize: "20px" }}>
                Optional HR App Integration
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400"}}
              >
                If you prefer to integrate Salarytap into your existing HR app,
                we offer this service for a setup fee. This ensures that
                Salarytap is fully integrated with your HR systems, providing a
                seamless experience for both your HR team and employees.
              </h6>
              <h4 className=" mt-4" style={{ fontSize: "20px" }}>
                One-Time Setup Fee
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                A one-time setup fee of Rs. 500 is charged to the employee at
                the time of their first withdrawal. This fee covers the initial
                setup and ensures that employees have access to all the benefits
                of the Salarytap service.
              </h6>
              <h4 className=" mt-4" style={{ fontSize: "20px" }}>
                Minimal Convenience Fee
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                A small convenience fee is applied to each withdrawal made by
                the employee. This nominal charge helps maintain the service
                while keeping it affordable for employees.
              </h6>
              <h4 className=" mt-4" style={{ fontSize: "20px" }}>
                Interest-Free Withdrawals
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                Withdrawals made through Salarytap are completely interest-free.
                Employees can access their earned wages without worrying about
                accumulating interest, making it a financially sound option for
                managing short-term cash needs.
              </h6>
              <h4 className=" mt-4" style={{ fontSize: "20px" }}>
                Additional Benefits
              </h4>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                Instant Access to Funds: Employees can withdraw funds instantly
                into their bank account or make direct payments to third parties
                using a QR code.
              </h6>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400" }}
              >
                Flexible Repayment: Employees can repay the withdrawn amount
                anytime, restoring their available limit to the original amount.
                Comprehensive Support: We offer app support and an emergency
                call helpline available from 6 AM to midnight, ensuring that
                employees have access to assistance whenever they need it.
              </h6>
              <h6
                className=" mt-2"
                style={{ fontWeight: "400"   }}
              >
                With these features, Salarytap provides a flexible,
                cost-effective solution for employees needing early access to
                their wages, while also offering easy and affordable integration
                options for employers.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Pricing;
