import React from "react";

function CreditPolicy() {
  return (
    <div className="p-3">
      <div className="pt-3">
        <div className="card">
          <div className="p-3 pt-4">
            {/* <h5 style={{ color: "#66a725", fontSize: "22px" }}>
              Category A (Blue chip) Credit rules
            </h5> */}
            <div className="table-responsive pt-3">
              <table className="table table-striped border">
                <tbody>
                  <tr>
                    <th className="w-25"></th>
                    <th  className="w-25" style={{ color: "#66a725", fontSize: "22px" }}>
                      Open Market/Non Assured
                    </th>
                    <th className="w-25" style={{ color: "#66a725", fontSize: "22px" }}>
                      Assured (Cat A)
                    </th>
                    <th className="w-25" style={{ color: "#66a725", fontSize: "22px" }}>
                      Assured (Cat B){" "}
                    </th>
                  </tr>
                  <tr>
                    <th className="w-25">Loan Amount</th>
                    <td className="w-25">
                      If EPF check is positive - CB score 700 Limit Rs.5000
                    </td>
                    <td className="w-25">As per assigned credit limit by employer</td>
                    <td className="w-25">
                      Lower of Rs.25,000 or the assigned limit by the employer
                    </td>
                  </tr>
                  <tr>
                    <th className="w-25">Conv Fees</th>
                    <td className="w-25" style={{ color: "red" }}>3% on withdrawal</td>
                    <td className="w-25" style={{ color: "red" }}>1.5% on withdrawal</td>
                    <td className="w-25" style={{ color: "red" }}>3% on withdrawal</td>
                  </tr>
                  <tr>
                    <th className="w-25">One time Set up fee</th>
                    <td className="w-25" style={{ color: "red" }}>Rs.300</td>
                    <td className="w-25" style={{ color: "red" }}>Rs.300</td>
                    <td className="w-25" style={{ color: "red" }}>Rs.300</td>
                  </tr>
                  <tr>
                    <th className="w-25">Subvention</th>
                    <td className="w-25">25% of Conv fees + Set up fees + 50% of late fees</td>
                    <td className="w-25">25% of Conv fees + Set up fees + 50% of late fees</td>
                    <td className="w-25">25% of Conv fees + Set up fees + 50% of late fees</td>
                  </tr>
                  <tr>
                    <th className="w-25">Age</th>
                    <td className="w-25">21-55 Yrs</td>
                    <td className="w-25">Min - 18 yrs</td>
                    <td className="w-25">Min - 18 yrs</td>
                  </tr>
                  <tr>
                    <th className="w-25">Late payment charges</th>
                    <td className="w-25">10% of outstanding amount</td>
                    <td className="w-25">10% of outstanding amount</td>
                    <td className="w-25">10% of outstanding amount</td>
                  </tr>
                  <tr>
                    <th className="w-25">Limit reset</th>
                    <td className="w-25">
                      On receipt of previous month due & late payment charges,
                      if any (Should not have gone above 30 DPD and that there
                      should not be 3 consecutive delays of payment past due
                      date).{" "}
                    </td>
                    <td className="w-25">On receipt of previous month due</td>
                    <td className="w-25">On receipt of previous month due</td>
                  </tr>
                  <tr>
                    <th className="w-25">Data point required</th>
                    <td className="w-25">
                      Name, Mobile No, Aadhaar No, PAN No, Pincode, mail id,
                      Employer name & pincode, bank details
                    </td>
                    <td className="w-25">Aadhaar No, PAN No, mail id, bank details</td>
                    <td className="w-25">Aadhaar No, PAN No, mail id, bank details</td>
                  </tr>
                  <tr>
                    <th className="w-25">OKYC</th>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                  </tr>
                  <tr>
                    <th className="w-25">PAN / Aadhaar Linking</th>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                  </tr>
                  <tr>
                    <th className="w-25">Penny drop only</th>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Mandatory</td>
                  </tr>
                  <tr>
                    <th className="w-25">Credit bureau</th>
                    <td className="w-25">
                      1. Score - Min 600 <br /> 2. Overdue amt - Not allowed{" "}
                      <br />
                      3. Write off amt - Not allowed <br />
                      4. Repayment delay - No DPD over 30 days allowed in last 3
                      months <br />
                      5. NTC allowed
                    </td>
                    <td className="w-25"></td>
                    <td className="w-25"></td>
                  </tr>
                  <tr>
                    <th className="w-25">Contact</th>
                    <td className="w-25">Min 50</td>
                    <td className="w-25">Not applicable</td>
                    <td className="w-25">Not applicable</td>
                  </tr>
                  <tr>
                    <th className="w-25">Bank statement</th>
                    <td className="w-25">
                      1. Minimum last 3 months
                      <br />
                      2. Minimum Salary in all 3 months need to be Rs.10000 and
                      average salary Rs. 15000
                    </td>
                    <td className="w-25">Not applicable</td>
                    <td className="w-25">Not applicable</td>
                  </tr>
                  <tr>
                    <th className="w-25">EPF check</th>
                    <td className="w-25">
                      Mandatory
                      <br />
                      1. Match employer name and that employer has either more
                      than 25 employees or EPF paid is at least Rs.1 Lakh
                      <br />
                      2. EPF output DOB matches with Pan DOB
                      <br />
                      3. Name matches
                      <br />
                      4. 3 months with the current employer
                    </td>
                    <td className="w-25">Not applicable</td>
                    <td className="w-25">Not applicable</td>
                  </tr>
                  <tr>
                    <th className="w-25">Pincode</th>
                    <td className="w-25">All pincodes allowed except North eastern states</td>
                    <td className="w-25">Not applicable</td>
                    <td className="w-25">Not applicable</td>
                  </tr>
                  <tr>
                    <th className="w-25">Mobile no linking</th>
                    <td className="w-25">
                      Mobile no should be linked to Aadhaar and Salary bank
                      account
                    </td>
                    <td className="w-25">Mobile no should be linked to Aadhaar</td>
                    <td className="w-25">Mobile no should be linked to Aadhaar</td>
                  </tr>
                  <tr>
                    <th className="w-25">Credit Appraisal</th>
                    <td className="w-25">Mandatory</td>
                    <td className="w-25">Not applicable</td>
                    <td className="w-25">Not applicable</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditPolicy;
