import React from "react";

export default function EmployeeDetail() {
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Employee Details
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">Search Employee</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
