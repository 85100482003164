import React from "react";
import label from "../../../image/lebal-footer.jpg";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import instagram from "../../../image/instagram.png";
function Footer() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="container">
        <div className="px-md-5 mt-3">
          <div
            className="row"
            style={{
              backgroundColor: "rgb(239, 239, 239)",
              color: "#fff",
              padding: 4,
              margin: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="col-12 col-md-4 col-lg-4 px-5 d-flex flex-column">
              <button
                className="btns  fw-semibold"
                onClick={() => navigate("/greenfinch/about")}
              >
                About
              </button>
              <button
                className="btns  fw-semibold"
                onClick={() => navigate("/greenfinch/howItWorks")}
              >
                How it Works
              </button>
              <button
                className="btns text-nowrap fw-semibold"
                onClick={() => navigate("/greenfinch/grievance-redressal")}
              >
                Grievance Redressal
              </button>
              <button
                className="btns  fw-semibold"
                onClick={() => navigate("/greenfinch/privacy-policy")}
              >
                Privacy policy
              </button>
              <button
                className="btns text-nowrap fw-semibold"
                onClick={() => navigate("/greenfinch/term-conditions")}
              >
                Terms and Conditions
              </button>
              <button
                className="btns text-nowrap fw-semibold"
                onClick={() => navigate("/greenfinch/media")}
              >
                Media
              </button>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 d-flex flex-column">
              <div className="d-flex justify-content-start">
                <button
                  className="btns custom-login text-center"
                  onClick={() => navigate("/")}
                >
                  <b>LOGIN</b>
                </button>
              </div>

              <div className="d-flex justify-content-start">
                <button
                  className="btns mt-2 custom text-center"
                  onClick={() => navigate("/greenfinch/talkToSales")}
                >
                  <b>Talk to Sales</b>
                </button>
              </div>
              <div className="d-flex justify-content-start">
                <button
                  className="btns mt-2 primary-btn text-center"
                  onClick={() => navigate("/greenfinch/createYourWorkspace")}
                >
                  <b> Create your workspace</b>
                </button>
              </div>
            </div>

            <div
              className="col-sm-12 col-md-4 col-lg-4 d-flex flex-column justify-content-start"
              style={{ alignItems: "center" }}
            >
              <div
                style={{
                  fontSize: 44,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=61561204493434"
                  target="_blank"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/salarytap.official/?hl=en"
                  target="_blank"
                >
                  <img src={instagram} width="65px" height="50px" />
                </a>
              </div>
              <div style={{ width: "230px" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.ctl.salarytaps"
                  target="_blank"
                >
                  <img
                    src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png"
                    width="100%"
                    height="100%"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
