import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { APIURL } from "../../../Config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function FinanceDisbursementReport() {
  const email = localStorage.getItem("emailId");
  const [showModal, setShowModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const history = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpBoxReference = useRef([]);
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }
  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }
  const clearOtpFields = () => {
    setOtp(Array(6).fill(""));
    otpBoxReference.current[0].focus();
  };
  useEffect(() => {
    disbursementReport();
  }, []);

  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonth("0");
  }, []);

  const disbursementReport = () => {
    const data = { email: email, status: month };
    setIsLoader(true);
    axios
      .request(
        `${APIURL}/corporate/financialdisbursementReport/status/${month}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        setIsLoader(false);
        console.log("response", response);
        setData(response?.data?.financeDisbursmentList);
        setTotal(response?.data?.totalDisbursementAmount);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
      });
  };
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Disbursement Report
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center justify-content-end">
                    <div>
                      <select
                        className="form-select mx-md-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                      >
                        {months.map(({ name, value }) => (
                          <option key={value} value={value}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-md-2">
                      <button
                        className="btn btn-success border"
                        style={{ background: "#66a725", color: "#fff" }}
                        onClick={disbursementReport}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Client name</th>
                          <th>LID</th>
                          <th>Mobile</th>
                          <th>Date of disb</th>
                          <th>Employer name/code</th>
                          <th>Loan amt</th>
                          <th>Set up fees</th>
                          <th>Convenience fee</th>
                          <th>Disbursed amt</th>
                          <th>Payable to Salarytap</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.employer}</td>
                            <td
                              style={{
                                textAlign: "center",
                                whiteSpace: "normal",
                                fontSize: "15px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              {item.loanid}
                            </td>
                            <td>{item.mobileno}</td>
                            <td>
                              {item?.dtofdisb
                                ? moment(item.dtofdisb).format("DD-MM-YYYY")
                                : ""}
                            </td>

                            <td>{item.clientname}</td>

                            <td>{item.loanamt}</td>
                            <td>{item.setupfees}</td>
                            <td>{item.conveniencefees}</td>
                            <td>{item.disbursementamt}</td>
                            <td>{item.payabletosalarytap}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "18px",
                        fontWeight: "bolder",
                      }}
                    >
                      Total Disbursement Amount : {total}
                    </div>
                  </div>
                </div>
                <Modal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  style={{ background: "#ffffffd1" }}
                >
                  <Modal.Body closeButton>
                    <Formik
                      initialValues={{ email: "" }}
                      // validationSchema={LoginSchema}
                      onSubmit={(values) => {
                        const hasEmptyString = otp.some(
                          (value) => value === ""
                        );
                        if (!hasEmptyString) {
                          const data = {
                            otp: parseInt(otp.join("")),
                            email: values.email,
                          };
                          console.log("data", data);
                          setIsLoader(true);
                          axios
                            .request(`${APIURL}/corporate/staticlogin`, {
                              method: "POST",
                              data: data,
                              headers: {
                                "Content-Type": "application/json",
                              },
                            })
                            .then((response) => {
                              console.log("response", response);
                              localStorage.setItem(
                                "workspace",
                                response?.data?.workspaceid
                              );
                              localStorage.setItem(
                                "userId",
                                response?.data?.userId
                              );
                              localStorage.setItem(
                                "emailId",
                                response?.data?.emailId
                              );
                              localStorage.setItem(
                                "corporateId",
                                response?.data?.corporateId
                              );
                              localStorage.setItem(
                                "token",
                                response?.data?.token
                              );
                              history("/greenfinch/dashboard");
                            })
                            .catch((error) => {
                              console.log("error", error);
                              setIsLoader(false);
                              toast.warning("Invalid...");
                            });
                        } else {
                          toast.error("All fields required");
                        }
                      }}
                    >
                      {({ touched, errors }) => (
                        <>
                          <Form className=" ">
                            <div>
                              <div>
                                <label>Email*</label>
                                <Field
                                  type="email"
                                  name="email"
                                  placeholder="Enter email"
                                  className={`form-control mt-2
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="invalid-feedback"
                                />
                                <div className="mt-2">
                                  <p
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                    className=""
                                  >
                                    Enter Otp*
                                  </p>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-lg-12 text-center">
                                    <div
                                      className=""
                                      style={{
                                        flexDirection: "row",
                                        columnGap: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {otp.map((digit, index) => (
                                        <input
                                          key={index}
                                          value={digit}
                                          maxLength={1}
                                          onChange={(e) =>
                                            handleChange(e.target.value, index)
                                          }
                                          onKeyUp={(e) =>
                                            handleBackspaceAndEnter(e, index)
                                          }
                                          ref={(reference) =>
                                            (otpBoxReference.current[index] =
                                              reference)
                                          }
                                          style={{
                                            height: "45px",
                                            width: "45px",
                                          }}
                                          className={`border w-12 h-12 text-4xl text-black p-3 rounded-md block bg-light focus:border-2 focus:outline-none appearance-none text-center`}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  className="btn btn-success mt-3"
                                  type="submit"
                                  style={{
                                    background: "#66a725",
                                    color: "#fff",
                                  }}
                                >
                                  {isLoader ? (
                                    <div
                                      className="spinner-border text-success"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
