import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

import NavbarLogin from "./Navbar/Navbar";
import { APIURL } from "../../Config";

export default function TakeToSales() {
  const [alert, setAlert] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [name, setName] = useState("");
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("required")
      .min(10, "MobileNo must be 10 characters at minimum")
      .max(10, "MobileNo must be 10 characters at maximum"),
    empname: Yup.string().required("required"),
    corporateName: Yup.string().required("required"),
    noofemployees: Yup.string().required("required"),
    emailId: Yup.string().email("Invalid email format").required("required"),
  });

  const backToWeb = () => {
    history("/");
  };

  React.useEffect(() => {
    // const tokenStatus = localStorage.getItem("token");
    // const role = localStorage.getItem("role");
    // //  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // if (!tokenStatus == "") {
    //   history("/admin");
    // }
  }, []);

  const talkToSales = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        onConfirm={() => {
          setAlert(null);
          setName("");
          history("/");
        }}
        confirmBtnStyle={{ backgroundColor: "#66a725", border: "none" }}
      >
        <h6>Dear {name},</h6>
        <h6>
          Thank you for your interest in Salarytap. Our team will contact you
          shortly.
        </h6>
        <h6>Regards,&nbsp;Team Ontap Technologies</h6>
      </SweetAlert>
    );
  };
  return (
    <>
      {alert}
      <div className="style">
        <Formik
          initialValues={{
            empname: "",
            emailId: "",
            designation: "",
            corporateName: "",
            noofemployees: "",
            mobile: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { resetForm }) => {
            setIsLoader(true);
            axios
              .request(`${APIURL}/corporate/talktosales`, {
                method: "POST",
                data: values,
                headers: {
                  "Content-Type": "application/json",
                 
                },
              })
              .then((response) => {
                console.log("response:::", response);
                setIsLoader(false);
                resetForm();
                if (response?.data?.success === true) {
                  talkToSales();
                } else {
                  toast.warning(
                    "workspace already created with this email id."
                  );
                  history("/");
                }
              })
              .catch((error) => {
                console.log("error", error);
                setIsLoader(false);
                toast.warning("User not exist...");
                resetForm();
              });
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <>
              <Form
                className="Loginform"
                style={{ padding: "30px 35px 20px 35px" }}
              >
                <div>
                  <div>
                    <h4
                      className="mt-3 mb-4 text-center"
                      style={{ color: "#66a725", fontWeight: 800 }}
                    >
                      Talk to Sales
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <p className="m-0 mb-2" style={{ color: "red" }}>
                      (Please fill this form only if you are an Employer)
                    </p>
                  </div>
                  <div className="">
                    <label>Work Email*</label>
                    <Field
                      type="email"
                      name="emailId"
                      placeholder="Enter work email"
                      className={`form-control
                          ${
                            touched.emailId && errors.emailId
                              ? "is-invalid"
                              : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="emailId"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>Employer Name*</label>
                    <Field
                      type="text"
                      name="corporateName"
                      placeholder="Enter employer name"
                      className={`form-control
                          ${
                            touched.corporateName && errors.corporateName
                              ? "is-invalid"
                              : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="corporateName"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>Your Name*</label>
                    <Field
                      type="text"
                      name="empname"
                      placeholder="Enter name"
                      onChange={(event) => {
                        setFieldValue("empname", event.target.value);
                        setName(event.target.value);
                      }}
                      className={`form-control
                          ${
                            touched.empname && errors.empname
                              ? "is-invalid"
                              : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="empname"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>Mobile No*</label>
                    <Field
                      type="number"
                      name="mobile"
                      placeholder="Enter mobile no"
                      className={`form-control
                          ${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="mobile"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>No. of employees*</label>
                    <Field
                      type="number"
                      name="noofemployees"
                      placeholder="Enter no. of employees"
                      className={`form-control
                          ${
                            touched.noofemployees && errors.noofemployees
                              ? "is-invalid"
                              : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="noofemployees"
                      className="invalid-feedback"
                    />
                  </div>

                  <button
                    className="btn btn-success mt-3"
                    type="submit"
                    style={{ backgroundColor: "#66a725" }}
                  >
                    {isLoader ? (
                      <div
                        className="spinner-border text-success"
                        role="status"
                      ></div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-end">
                    {" "}
                    <button
                      className="btn  btn-light mt-4 "
                      type="button"
                      style={{ width: "70px" }}
                      onClick={() => backToWeb()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
        {/* {show && (
        <div className="Loginform">
          Thank you for your interest. You will shortly hear from us
        </div>
      )} */}
      </div>
    </>
  );
}
