import React, { useEffect } from "react";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import { useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="container">
          <div className="px-md-5">
            <div className="card bg-light ">
              <div
                className="h4 text-center"
                style={{
                  backgroundColor: "#66a725",
                  color: "#fff",
                  padding: 4,
                  borderTopLeftRadius: "0.375rem",
                  borderTopRightRadius: "0.375rem",
                }}
              >
                Privacy Policy{" "}
              </div>
              <div className="p-4">
                <div>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    We, at Ontap Technologies Private Limited{" "}
                    <b>(“Salarytap”</b> or <b>“We”)</b>
                    understand Privacy and its value. Therefore, it is all the
                    more important for us to make<b> YOU (“You”</b> or{" "}
                    <b> “Customer”</b> or <b>“User”)</b>, the User of the
                    website www.Salarytap.in (the “Website”) and its associated
                    mobile applications, Salarytap
                    <b> (“Application”)</b>
                    (collectively, the<b> “Platform”</b>) understand the reason
                    behind collection of your information and its usage and the
                    manner in which we collect, use, store and share information
                    about you
                    <b>(“Privacy Policy”).</b> This Privacy Policy has been
                    prepared in compliance with:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a.</div>
                    <div className="ml-2">
                      Information Technology (Reasonable Security Practices and
                      Procedures and Sensitive Personal Data or Information)
                      Rules, 2011;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b.</div>
                    <div className="ml-2">
                      {" "}
                      Information Technology (Intermediary Guidelines and
                      Digital Media Ethics Code) Rules, 2021{" "}
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c. </div>
                    <div className="ml-2">
                      {" "}
                      Guidelines on Digital Lending issued by the Reserve Bank
                      of India (RBI), 2022;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>d.</div>
                    <div className="ml-2">
                      {" "}
                      Other applicable acts, regulations and rules which
                      requires the publishing of a privacy policy for handling
                      of or dealing in personal information including sensitive
                      personal data or information and all applicable laws,
                      regulations, guidelines provided by applicable regulatory
                      authorities including but not limited to the RBI.
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    This Privacy Policy is incorporated into and at all times is
                    subject to and is to be read in conjunction with the Terms
                    of Use of the Platform.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    CONSENT
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    You hereby expressly consent to provide the information that
                    may be required in relation to the Services (as defined
                    below) being rendered on the Platform by us. You acknowledge
                    that we shall collect the information detailed under this
                    Privacy Policy to facilitate lending & non-lending services
                    by partnering with various financial lenders, third parties,
                    service providers, etc based on your requirement to avail
                    such Services <b>(“Services”).</b>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Salarytap will only be using the information for providing
                    the Services to you.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    In order to avail any Services being provided by Salarytap
                    by itself or in partnership with the lenders or other third
                    parties it is important that{" "}
                    <b>
                      YOU READ, UNDERSTAND, ACKNOWLEDGE AND UNCONDITIONALLY
                      AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
                      PRIVACY POLICY.
                    </b>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    <b>
                      IF YOU DO NOT AGREE TO THIS POLICY OR ANY PART THEREOF,
                      PLEASE DO NOT USE/ ACCESS/ DOWNLOAD/ INSTALL THE PLATFORM
                      OR ANY PART THEREOF.
                    </b>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    For the users consenting to continue accessing the Platform
                    and avail the Services, this Privacy Policy explains our
                    policies and practices regarding the collection, use, and
                    disclosure of Your information.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    COLLECTION OF INFORMATION
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The collection of information under this Privacy Policy are
                    conducted for the following categories of services:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>a.</div>
                    <div className="ml-2">
                      (i)
                      <b> Part A: Information for Digital Lending Services:</b>
                      Information collected by Platform for facilitation of
                      Loans being disbursed by the financial lending partners
                      whose details are available on the Website and the
                      Application and who are registered with the Reserve Bank
                      of India (“
                      <b>Lending Partners</b>”).
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b.</div>{" "}
                    <div className="ml-2">
                      {" "}
                      (ii) <b>Part B: Information for Non-Lending Services:</b>
                      Information collected by Platform while registering a User
                      on the Platform or while providing Value Added Services
                      (i.e. all services other than facilitation of loans).
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>a.</div>
                    <div className="ml-2">
                      <b> A. Information we collect about you</b> <br />
                      In order to facilitate the lending and non-lending
                      services, Salarytap will be required to access, collect
                      and share Personal Information with its lending partners
                      that may be banks or NBFCs registered with the Reserve
                      Bank of India or any other third-party providing value
                      added services in partnership with Salarytap. In such
                      cases, Salarytap will share the information securely and
                      ensure that all personal information recipients comply
                      with confidentiality, fidelity and secrecy obligations and
                      sign covenants in this regard. Salarytap may make
                      information available to third parties that are financial
                      and non-financial companies, government agencies, courts,
                      legal investigators, and other non-affiliated third
                      parties as requested by You or Your authorized
                      representative, or otherwise when required or permitted by
                      law.
                    </div>
                  </h6>
                  <h6
                    className="mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    1.{" "}
                    <i>
                      <u>User Personal Information</u>
                    </i>
                    : The data points we collect from You for both lending and
                    non-lending services include, inter alia, your full name,
                    email id, PAN, Aadhaar, GST Network user id & password,
                    address, mobile number, postal code.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    2.{" "}
                    <i>
                      <u>Social Account Information</u>
                    </i>
                    : Salarytap may provide you with the option to register
                    using social accounts (Google or Facebook) to access the app
                    and shall collect only such registered email id and user
                    public profile information like name, email, ASID depending
                    on the platform used by You to log-into the Application
                    during registration/ sign in process in the Platform.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    How we use this information: We may collect and store email
                    id, name and address associated with that account for the
                    purpose of verification and to pre-populate relevant fields
                    in the course of Platform interface. However, we shall not
                    collect / store account passwords.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    3.{" "}
                    <i>
                      <u>SMS Information</u>
                    </i>
                    : Salarytap collects, stores and monitors SMS information
                    from your device.
                  </h6>
                  <h6
                    className=" mt-3 "
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    How we use this information: We use this data to provide you
                    with updates or confirmation of any actions taken in our
                    Platform during the term of Services. We shall collect any
                    SMS information for facilitating you any lending services
                    and non-lending service including such purposes as may be
                    required by the Lending Partners or as per applicable law.
                    This category of information is only collected for providing
                    the non-lending services or our value-added services
                  </h6>
                  <h6
                    className=" mt-3 "
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    4.{" "}
                    <i>
                      <u>Device Information and Installed Apps data</u>
                    </i>
                    : We additionally collect certain device information
                    provided herein for our lending and non-lending services.
                    Information which the Application collects, and its usage,
                    depends on how you manage your privacy controls on your
                    device.
                  </h6>
                  <h6
                    className=" mt-3 "
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    (i) Device Information: When you install the Application, we
                    store the information we collect with unique identifiers
                    tied to the device you are using. We collect information
                    from the device when you download and install the
                    Application and explicitly seek permissions from You to get
                    the required information from the device. Additionally, we
                    also collect your Log information (via the domain server
                    through which the User accesses the App Search queries, IP
                    address, crashes, date etc for the purpose of improvising
                    the Application functionality. In addition to the above, we
                    also track and collect the data related to the performance
                    of the Application and other diagnostic data for identifying
                    and resolving any technical glitches that may be identified
                    from such data and also for improving the overall
                    functionality of the Application.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    How we use the information: We collect information about
                    your device to provide automatic updates and additional
                    security so that your account is not used in other people’s
                    devices. In addition, the information provides us valuable
                    feedback on your identity as a device holder as well as your
                    device behaviour, thereby allowing us to improve our
                    products interaction, quality of services and provide an
                    enhanced customized user experience to you. We further
                    collect other identifiable information such as your
                    transactions history on the Platform when you set up a free
                    account with us.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    (ii) Installed Application Data: We collect and transmit a
                    list of specific installed applications’ metadata
                    information which includes the application name, package
                    name, installed time, updated time, version name and version
                    code of each installed application on your device. This data
                    may be collected even when the app is closed or not in use.
                    How we use this information: We use this information for
                    your onboarding and Know Your Customer (KYC) purpose with
                    your explicit consent.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    <i>
                      <u>5. Location, Camera, Microphone Access</u>
                    </i>{" "}
                    : We will collect your device location and request camera
                    and microphone access for facilitating lending services as
                    well as providing any non-lending services only in
                    accordance with applicable laws.
                  </h6>
                  <h6
                    className=" mt-3 "
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    How we use this information: We shall collect your device
                    location information for verifying your address, for the KYC
                    and onboarding process for the Services. We may request
                    camera access to scan and capture the required KYC documents
                    as per the instructions of our Lending Partners in
                    accordance with applicable laws. We shall transfer such
                    information to our Lending Partners and shall not store any
                    such data. We require microphone permissions to enable a
                    two-way communication between our authorised agents and you
                    for the purpose of performing and completing your Video KYC
                    for the Lending Services. Your audio shall be recorded for
                    regulatory purpose.
                  </h6>
                  <h6
                    className="mt-4 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a.</div>
                    <div className="ml-2">
                      <b>
                        {" "}
                        B. Information about you we collect from third parties
                      </b>
                    </div>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. For making the Services available to you, we may collect
                    credit information by obtaining specific authorisations from
                    you (if required under applicable laws), from certain third
                    parties such as credit bureaus or credit rating companies as
                    your ‘authorised representative’ from time to time in
                    accordance with applicable laws during the loan journey as
                    may be requested by our Lending Partners.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    c. In order to facilitate credit products to you, we may
                    receive certain information pertaining to document
                    verification, repayment status etc from certain third
                    parties including NSDL, payment gateway providers.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    d. We may further collect your GST details from Official
                    GSTIN API stack or other relevant websites using the GST
                    Network user id and password details or OTP as provided by
                    you.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    e. We may further collect your bank account numbers or UPI
                    payment information on behalf of our lending partners to
                    facilitate collection and repayment of loan payments.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    f. We shall only collect this information on a need basis
                    strictly for the purpose of providing you with the Services.
                    The information collected from such third parties are not
                    retained by us. We collect this information as part of our
                    outsourcing obligations to our Lending Partners and is
                    directly transferred to the Lending Partners upon
                    collection.
                  </h6>
                  <h6 className="mt-4 ps-3" style={{ fontWeight: "400" }}>
                    a.
                    <b>
                      {" "}
                      B. Information about you we collect from third parties
                    </b>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. For making the Services available to you, we may collect
                    credit information by obtaining specific authorisations from
                    you (if required under applicable laws), from certain third
                    parties such as credit bureaus or credit rating companies as
                    your ‘authorised representative’ from time to time in
                    accordance with applicable laws during the loan journey as
                    may be requested by our Lending Partners.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    c. In order to facilitate credit products to you, we may
                    receive certain information pertaining to document
                    verification, repayment status etc from certain third
                    parties including NSDL, payment gateway providers.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    d. We may further collect your GST details from Official
                    GSTIN API stack or other relevant websites using the GST
                    Network user id and password details or OTP as provided by
                    you.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    e. We may further collect your bank account numbers or UPI
                    payment information on behalf of our lending partners to
                    facilitate collection and repayment of loan payments.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    f. We shall only collect this information on a need basis
                    strictly for the purpose of providing you with the Services.
                    The information collected from such third parties are not
                    retained by us. We collect this information as part of our
                    outsourcing obligations to our Lending Partners and is
                    directly transferred to the Lending Partners upon
                    collection.
                  </h6>
                  <h6 className=" mt-4 ps-3" style={{ fontWeight: "400" }}>
                    a.<b> C. Information you give us about you</b>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. In due course of using our Services, you are required to
                    submit data to enable our Services. We use this data to
                    create your profile and provide you with the best available
                    services. Mentioned below is some of the data we collect
                    from you:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>c.</div>{" "}
                    <div className="ml-2">
                      i. Data provided by you by filling in forms in the
                      Application or on the Website.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    ii. Data provided by corresponding with us (for example, by
                    e-mail or chat).
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    iii. Data and information, you provide when you register to
                    use the Website, download or register on our App, subscribe
                    to any of our Services (such as applying for a loan), search
                    for a Service, and when you report a problem with our App,
                    our Services, or any of our Sites.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    iv. Data including your name, address, gender, date of
                    birth, e-mail address, phone number, username, password and
                    other registration information.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    v. PAN Card, Aadhaar Card, financial information such as
                    employer name, monthly salary, bank account no., bank
                    statements, credit information, GST information, copies of
                    identification documents which are forwarded to our Lending
                    Partners for the onboarding of your application to avail the
                    services.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    vi. Data generated by your usage of our Platform.
                  </h6>
                  <h6
                    className=" mt-3"
                    style={{ fontWeight: "400", paddingLeft: "33px" }}
                  >
                    vii. Contact details (i.e. the phone number) of the third
                    party individuals (including your relatives and friends)
                    that are voluntarily added by you at the time of loan
                    application.{" "}
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    d. This data helps us create your profiles, complete
                    mandatory KYC as per the requirements of our Lending
                    Partners who offer you the Services, unlock and approve
                    loans and provide you with customized support in case of
                    issues. Please note that we do not store any data provided
                    by you except for the basic information such as name,
                    address, your contact details etc.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    e. Wherever possible, we indicate the mandatory and the
                    optional fields. You always have the option to not provide
                    any information by choosing not to use a particular service
                    or feature on the Platform. While you can browse some
                    sections of our Platform without being a registered member
                    as mentioned above, certain activities (such as availing of
                    loans from the third-party lenders on the Platform) require
                    registration and for you to provide the above details.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    f. We under no circumstances and at no point take any
                    biometric data from you for any of our services or
                    operations. In case, if any of our representatives ask for
                    the same from you, we request you to kindly refrain from
                    doing the same and address this concern to our Grievance
                    Officer (the details of the same have been provided below).
                  </h6>
                  <h6 className=" mt-4 ps-3" style={{ fontWeight: "400" }}>
                    a. <b>D. Storage of Personal Information</b>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. For the provision of lending Services, we shall only
                    store basic personal information such as your name, your
                    address, your contact information which are required to be
                    stored for carrying out our non-lending services. We ensure
                    that every data or information that we collect from you is
                    stored in servers located in India and the same is
                    compliance with all the statutory/regulatory obligations.
                    For other personal information collected as part of our
                    outsourcing services being provided to our Lending Partners,
                    we shall collect such information upon the instructions of
                    the Lending Partners and thereafter transfer the same to the
                    Lending Partners upon the completion of the preliminary
                    onboarding.
                  </h6>
                  <h6 className=" mt-4 ps-3" style={{ fontWeight: "400" }}>
                    a.<b> E. Collection of Certain Non-Personal Information</b>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. We automatically track certain information about you
                    based upon your behaviour on our Platform. We use this
                    information to do internal research on our users’
                    demographics, interests, and behaviour to better understand,
                    protect and serve our users and improve our services. This
                    information is compiled and analysed on an aggregated basis.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    c.{" "}
                    <i>
                      <u>Cookies</u>
                    </i>
                    : Cookies are small data files that a Website stores on Your
                    computer. We will use cookies on our Website similar to
                    other lending websites / apps and online marketplace
                    websites / apps. Use of this information helps Us identify
                    You in order to make our Website more user friendly. Most
                    browsers will permit You to decline cookies but if You
                    choose to do this it might affect service on some parts of
                    Our Website.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    d. If you choose to make a purchase through the Platform, we
                    collect information about your buying behaviour. We retain
                    this information as necessary to resolve disputes, provide
                    customer support and troubleshoot problems as permitted by
                    law. If you send us personal correspondence, such as emails
                    or letters, or if other users or third parties send us
                    correspondence about your activities or postings on the
                    Website, we collect such information into a file specific to
                    you.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    PURPOSE OF COLLECTION
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We shall use the information collected from you for
                    facilitating the lending and non-lending Services for the
                    following purposes as detailed below. We understand the
                    importance of your information and ensure that it is used
                    for the following intended purposes only.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The intended purpose of collecting information provided by
                    you is to:
                  </h6>
                  <h6
                    className=" mt-3 ps-3  d-flex "
                    style={{ fontWeight: "400" }}
                  >
                    <div> a.</div>
                    <div className="ml-1">
                      {" "}
                      establish identity and verify the same;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3  d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>b.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      to facilitate your KYC as per instructions from our
                      Lending
                    </div>
                    Partners;
                  </h6>
                  <h6
                    className=" mt-3 ps-3  d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c.</div>
                    <div className="ml-1">
                      {" "}
                      troubleshoot problems, monitor, improve and administer our
                    </div>
                    Platform;
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> d. </div>
                    <div className="ml-1">
                      provide our service i.e. facilitating loans to You or
                      providing our value-added services or non-lending services
                      to you.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> e. </div>{" "}
                    <div className="ml-1">
                      design and offer customized products and services offered
                      by
                    </div>
                    our third-party partners;
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>f. </div>{" "}
                    <div className="ml-1">
                      analyse how the Platform is used, diagnose service or
                    </div>
                    technical problems and maintain security;
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> g.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      send communications notifications, information regarding
                      the products or services requested by You or process
                      queries and applications that You have made on the
                      Platform;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> h.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      measure consumer interest and satisfaction in our products
                      and services and manage Our relationship with You;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> i. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      marketing and promotional purposes including sending you
                      promotional SMS, Email and WhatsApp and inform you about
                      online and offline offers, products, services, and
                      updates;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> j.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      conduct data analysis in order to improve the Services /
                      Products provided to the User;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>k.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      use the User information in order to comply with country
                      laws and regulations;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> l. </div>{" "}
                    <div className="ml-1">
                      use the User information in other ways permitted by law to
                      enable You to take financial services from our lending
                      partners.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> m.</div>
                    <div className="ml-1">
                      {" "}
                      resolve disputes and detect and protect us against
                      suspicious or illegal activity, fraud and other criminal
                      activity;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div>n.</div>
                    <div className="ml-1">
                      {" "}
                      customize your experience and enforce our terms and
                      conditions.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> o.</div>
                    <div className="ml-1">
                      the contact details (i.e. the phone number) of the third
                      party individuals (including your relatives and friends)
                      that are voluntarily added by you at the time of loan
                      application may be used for the purpose of establishing
                      contact in case we are unable to contact you, including at
                      the time of collection of installments.
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We will use and retain only such basic personal information
                    such as your name, contact information, address details and
                    such other information which are necessary for the provision
                    of Services and for such periods as necessary to provide You
                    the Services on the Platform, to comply with our legal
                    obligations, to resolve disputes, and enforce our
                    agreements.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    DISCLOSURE TO THIRD PARTIES
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We will share Your information only with our third parties
                    including our regulated financial partners, vendors etc for
                    facilitation of Services on the Platform.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We will share the information only in such manner as
                    described below:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a. </div>{" "}
                    <div className="ml-1">
                      We disclose and share Your information with the financial
                      service providers, banks or NBFCs and our Lending Partners
                      for facilitation of a loan or facility or line of credit
                      or purchase of a product;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b.</div>
                    <div className="ml-1">
                      {" "}
                      We share Your information with our third-party partners in
                      order to conduct data analysis in order to serve You
                      better and provide Services our Platform;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c.</div>{" "}
                    <div className="ml-1">
                      {" "}
                      We may disclose Your information, in order to enforce or
                      apply our terms of use or assign such information in the
                      course of corporate divestitures, mergers, or to protect
                      the rights, property, or safety of us, our users, or
                      others. This includes exchanging information with other
                      companies and organizations for the purposes of fraud
                      protection and credit risk reduction.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> d. </div>{" "}
                    <div className="ml-1">
                      We will disclose the data / information provided by a user
                      with other technology partners to track how the user
                      interact with the Platform on our behalf.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> e. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We and our affiliates may share Your information with
                      another business entity should we (or our assets) merge
                      with, or be acquired by that business entity, or
                      re-organization, amalgamation, restructuring of business
                      for continuity of business. Should such a transaction
                      occur than any business entity (or the new combined
                      entity) receiving any such information from us shall be
                      bound by this Policy with respect to your information.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> f. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We will disclose the information to our third-party
                      technology or third-party data source providers;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> g. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We will share Your information under a confidentiality
                      agreement with the third parties and restrict use of the
                      said information by third parties only for the purposes
                      detailed herein. We warrant that there will be no
                      unauthorised disclosure of your information shared with
                      third parties.{" "}
                    </div>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    h. We shall disclose your KYC journey or any data with
                    respect to the same to the relevant regulatory authorities
                    as a part of our statutory audit process. Please note that
                    your Aadhaar number shall never be disclosed.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We may share your personal information with the governmental
                    authorities, quasi-governmental authorities, judicial
                    authorities and quasi-judicial authorities if we are acting
                    under any duty, request or order as part of our legal
                    obligations and in accordance to the applicable laws. By
                    accepting this Privacy Policy, you hereby provide your
                    consent to disclose your personal information for such
                    regulatory disclosure.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Any disclosure to third parties is subject to the following:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      If we are under a duty to disclose or share your personal
                      data in order to comply with any legal or regulatory
                      obligation or request, we shall not seek your explicit
                      consent however we shall reasonably endeavour to notify
                      the same to you accordingly as the case may be as stated
                      under subclause 3;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We shall take your express consent in the event we share
                      your personal data with third parties;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We shall share your information with third-party only on a
                      need basis and only for the purpose stated hereunder, as
                      per the applicable laws.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> d. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We shall additionally seek express consent through a
                      separate consent for at appropriate stages of data
                      collection, if so required under applicable laws.{" "}
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> e. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Usage of your information by such third parties is subject
                      to their privacy policies. We share limited information
                      with them, strictly to the extent required. We recommend
                      you to have a look at the privacy policies of such third
                      parties.{" "}
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    List of entities with whom your information is shared can be
                    assessed at https://www.Salarytap.in/our-lending-partners
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    DATA RETENTION AND DELETION
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We at Salarytap promise to protect your personal data from
                    unauthorized access, misuse, and disclosure using the right
                    security measures based on the type of data and how we are
                    processing the same. We retain information about you to
                    provide a seamless experience, to contact you in case of
                    support required and about your account, to detect,
                    mitigate, prevent, and investigate fraudulent or illegal
                    activities during the course of the Services. We retain your
                    data for as long as necessary to provide you with our
                    services. We may also retain and use your basic personal
                    information inter alia as name, contact number,
                    transactional details and address details as necessary to
                    comply with our legal obligations, resolve disputes, and
                    enforce our agreements which shall always be in accordance
                    with applicable laws. Subject to this section, we may delete
                    your data upon reasonable written request for the same at
                    any stage. However, you may not be able to use our services
                    after deletion.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    LINK TO THIRD-PARTY SDK AND OTHER SITES
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The App has a link to registered third party SDKs,
                    Application Programming Interface (API) integrations,
                    redirections which collects data on our behalf and data is
                    stored to a secured server. We ensure that our third-party
                    service provider takes extensive security measures in order
                    to protect your personal information against loss, misuse or
                    alteration of the data as required under the applicable
                    laws.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Our third-party service provider employs separation of
                    environments and segregation of duties and have strict
                    role-based access control on a documented, authorized,
                    need-to-use basis. The stored data is protected and stored
                    by application-level encryption. They enforce key management
                    services to limit access to data.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Furthermore, our registered third-party service provider
                    provides hosting security – they use industry-leading
                    anti-virus, anti-malware, intrusion prevention systems,
                    intrusion detection systems, file integrity monitoring, and
                    application control solutions.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We don't allow unauthorized access to your non-public
                    personal contacts or financial transaction SMS data with any
                    third party in relation to Lending Services other than our
                    Lending Partners. However, for Non-Lending Services (Value
                    Added Services i. e. Expense Tracker), we may allow third
                    parties to access your financial transaction SMS subject to
                    your explicit consent.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Our Platform links to other websites that may collect
                    information about you. We are not responsible for the
                    privacy practices or the content of those linked websites.
                    With this Policy we’re only addressing the disclosure and
                    use of data collected by Us. Their data collection
                    practices, and their policies might be different from this
                    Policy and We do not have control over any of their policies
                    neither do we have any liability in this regard.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    SECURITY PRECAUTIONS
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The Platform intends to protect your information and to
                    maintain its accuracy as confirmed by you. We implement
                    reasonable physical, administrative and technical safeguards
                    to help us protect your information from unauthorized
                    access, use and disclosure. For example, we encrypt all
                    information when we transmit over the internet. We also
                    require that our registered third-party service providers
                    protect such information from unauthorized access, use and
                    disclosure.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Our Platform has stringent security measures in place to
                    protect the loss, misuse and alteration of information under
                    control. We endeavour to safeguard and ensure the security
                    of the information provided by you. We use Secure Sockets
                    Layers (SSL) based encryption, for the transmission of the
                    information, which is currently the required level of
                    encryption in India as per applicable law.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We blend security at multiple steps within our products with
                    the state-of-the-art technology to ensure our systems
                    maintain strong security measures and the overall data and
                    privacy security design allow us to defend our systems
                    ranging from low hanging issue up to sophisticated attacks.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    We aim to protect from unauthorized access, alteration,
                    disclosure or destruction of information we hold, including:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We use encryption to keep your data private while in
                      transit;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We offer security feature like an OTP verification to help
                      you protect your account;{" "}
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c. </div>{" "}
                    <div className="ml-1">
                      We review our information collection, storage, and
                      processing practices, including physical security
                      measures, to prevent unauthorized access to our systems;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> d. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We restrict access to personal information to our
                      employees, contractors, and agents who need that
                      information in order to process it. Anyone with this
                      access is subject to strict contractual confidentiality
                      obligations and may be disciplined or terminated if they
                      fail to meet these obligations;
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> e. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Compliance & Cooperation with Regulations and applicable
                      laws;{" "}
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> f. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We regularly review this Privacy Policy and make sure that
                      we process your information in ways that comply with it.{" "}
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> g. </div>{" "}
                    <div className="ml-1"> Data transfers; </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> h. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      We ensure that Aadhaar number is not disclosed in any
                      manner.{" "}
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Data protection laws vary among countries, with some
                    providing more protection than others. We also comply with
                    certain legal frameworks relating to the transfer of data as
                    mentioned and required under the Information Technology Act,
                    2000, rules and the amendments made thereunder.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    When we receive formal written complaints, we respond by
                    contacting the person who made the complaint. We work with
                    the appropriate regulatory authorities, including local data
                    protection authorities, to resolve any complaints regarding
                    the transfer of your data that we cannot resolve with you
                    directly.
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    YOUR RIGHTS
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    <u> Modifying or rectifying your information</u>: In the
                    event that any Personal Information provided by You is
                    inaccurate, incomplete or outdated then You shall have the
                    right to provide Us with the accurate, complete and up to
                    date data and have Us rectify such data at Our end
                    immediately. We urge You to ensure that You always provide
                    Us with accurate and correct information/data to ensure Your
                    use of Our Services is uninterrupted. In case of
                    modification of Personal Information, Users will be required
                    to furnish supporting documents relating to change in
                    Personal Information for the purpose of verification by the
                    Company.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    <u> Your Privacy Controls</u>: You have certain choices
                    regarding the information we collect and how it is used:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Your device may have controls that determine what
                      information we collect. For example, you can modify
                      permissions on your Android/iOS device or Browser to
                      remove any permissions that may have been given. However,
                      Platform does not provide a guarantee of Services if any
                      such controls are exercised.
                    </div>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    b. Delete the App from your device
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> c. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      You can also request to remove content from our servers in
                      accordance with Clause C.
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    <u> Withdrawal/Denial of consent</u>: You acknowledge that
                    the Platform has duly collected the information with your
                    consent and you have the option to not provide such
                    information, or deny consent for use of specific information
                    or revoke the consent already given. However, any withdrawal
                    of such personal information will not be permitted in case
                    any Service availed by You is active. Where a consent has
                    been withdrawn the Platform does not guarantee or be liable
                    for providing such Service. You shall have the following
                    rights pertaining to your information collected by us.
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> a. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Deny Consent: You shall have the right to deny consent for
                      use of specific data, restrict disclosure to third
                      parties, data retention, revoke consent already granted to
                      collect personal data and if required, make the App
                      delete/ forget the data. However, any such denial will not
                      prejudice the right of the Lending Partners to retain any
                      data in relation to the loans availed by you or by the
                      non-lending service providers in relation to the
                      non-lending services provided to you. Further, in case of
                      a denial of a consent, the Platform does not provide a
                      guarantee or will not be liable towards the continued
                      facilitation of the Services if any such controls are
                      exercised.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> b. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Withdraw Consent: You may withdraw Your consent to contact
                      You, for the continued collection, use or disclosure of
                      Your information, at any time, or request for deletion of
                      your Login account by raising a request on the Platform or
                      by mailing Us at care.salarytap@gmail.com. However,
                      Platform does not provide a guarantee of Services if any
                      such controls are exercised. Further, if You have availed
                      any loan facilities from Our lending partner, the lending
                      partner shall have the right to continue processing Your
                      information till such credit facility has been repaid in
                      full, along with any interest and dues payable and/or for
                      such period as may be allowed under applicable law.
                      However, We, shall not retain Your data and information if
                      it is no longer required by Us and there is no legal
                      requirement to retain the same. Do note that multiple
                      legal bases may exist in parallel, and We may still have
                      to retain certain data and information at any time. Also,
                      the information may still be used for execution of any
                      outstanding or termination activity of any Lending or
                      Non-lending Services.
                    </div>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    <u>Report an issue</u>: You have a right to report a
                    security incident to the GRO (detailed mentioned
                    hereinbelow). You are entitled shall be entitled to prevent
                    unauthorised such usage of your information by our
                    personnel/agents by informing us, within 10 days of being
                    informed of the proposed use, that you do not wish to
                    disclose such information. You can also exercise the right
                    at any time by contacting us at care.salarytap@gmail.com
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Notwithstanding anything to the contrary stated above, the
                    following are specific scenarios listed below which may have
                    consequences to your withdrawal of consent:
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> 1. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Marketing and Communication: The consent for this
                      information be withdrawn if You write an email to the
                      email address provided under clause VIII.
                    </div>
                  </h6>
                  <h6
                    className=" mt-3 ps-3 d-flex"
                    style={{ fontWeight: "400" }}
                  >
                    <div> 2. </div>{" "}
                    <div className="ml-1">
                      {" "}
                      Information/data deletion may not be implemented for
                      ongoing Services including loan, insurance policy or
                      digital gold products.
                    </div>
                  </h6>
                  <h3 className=" mt-5" style={{ fontSize: "20px" }}>
                    GRIEVANCE REDRESSAL
                  </h3>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    You may make a request for deleting any information from the
                    Platform at any stage upon making a request to Us in the
                    following manner:
                  </h6>
                  <h6 className=" mt-3 ps-2" style={{ fontWeight: "400" }}>
                    a. <b>Grievance Officer</b>
                  </h6>
                  <h6 className=" mt-4 ps-3" style={{ fontWeight: "400" }}>
                    We are committed to resolving any problem or issue of yours.
                    If you are unsatisfied with the resolution of your issue,
                    you can submit your grievance to{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      Care.salarytap@gmail.com
                    </span>{" "}
                    or call our Grievance Redressal Officer, Gautam Kumar on{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      +919810688661
                    </span>{" "}
                    on working days, between Monday and Friday from 9.30 am to
                    5.30 pm.
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    Ombudsman Scheme for Non-Banking Financial Companies, 2021
                    The customer can also approach Ombudsman under THE RESERVE
                    BANK - INTEGRATED OMBUDSMAN SCHEME, 2021, in case of any
                    complaint at
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    C/o Reserve Bank of India
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    Sansad Marg, New Delhi -110001
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    STD Code: 011 Telephone No: 23724856
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    Fax No :{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      23725218-19
                    </span>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    Email :{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      crpc@rbi.org.in.
                    </span>
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    The complaints under the Scheme made online shall be
                    registered on the portal({" "}
                    <a href="https://cms.rbi.org.in/" target="_blank">
                      https://cms.rbi.org.in
                    </a>
                    ).
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    The customer can also raise complaint using Sachet link: (
                    <a
                      href="https://sachet.rbi.org.in/Complaints/Add"
                      target="_blank"
                    >
                      https://sachet.rbi.org.in/Complaints/Add
                    </a>
                    ).
                  </h6>
                  <h6 className=" mt-3 ps-3" style={{ fontWeight: "400" }}>
                    Complaints in electronic mode (E-mail) and physical form,
                    including postal and hand-delivered complaints, shall be
                    addressed and sent to the place where the Centralised
                    Receipt and Processing Centre of the Reserve Bank is
                    established, for scrutiny and initial processing.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
