import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DownloadTableExcel } from "react-export-table-to-excel";
import { APIURL } from "../../../Config";
import axios from "axios";

const All = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");

  const [selectDate, setSelectDate] = useState(false);
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 7);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);
  const handleSubmit = () => {
    console.log(":::::::", dateValue1, dateValue2);
    axios
      .request(
        `${APIURL}/api/V2/clientStatus/${ids}/3/${dateValue2}/${dateValue1}`,
        {
          method: "GET",

          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div class="pt-4">
      <h1 className="text-center my-3">ALL List</h1>

      <div className="pt-3">
        <div className="card">
          <div className="p-3 pt-4">
            <div className="d-flex justify-content-end">
              {/* <div>
                <DownloadTableExcel
                  filename="Login Dump"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button
                    className="btn btn-success mb-3 mb-md-0"
                    style={{ width: "120px", background: "#66a725" }}
                  >
                    Download
                  </button>
                </DownloadTableExcel>
              </div> */}
              <div className="">
                {!selectDate ? (
                  <button
                    className="btn btn-info"
                    style={{ width: "150px" }}
                    onClick={() => setSelectDate(true)}
                  >
                    Select Date
                  </button>
                ) : (
                  <>
                    <div className="d-flex" style={{ width: "500px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div>
                          <DatePicker
                            className="ml-2"
                            label="To Date"
                            value={dateValue1}
                            inputFormat="dd-MM-yyyy"
                            onChange={(e) =>
                              setDateValue1(moment(e).format("YYYY-MM-DD"))
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        <div>
                          <DatePicker
                            className="ml-2"
                            label="From Date"
                            value={dateValue2}
                            inputFormat="dd-MM-yyyy"
                            onChange={(e) =>
                              setDateValue2(moment(e).format("YYYY-MM-DD"))
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      </LocalizationProvider>
                      <div className="mt-2 mx-2">
                        <button
                          className="btn btn-success"
                          style={{
                            background: "#66a725",
                            width: "100px",
                          }}
                          onClick={() => handleSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="table-responsive mt-3">
              <table class="table table-boarded">
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Case No</th>
                    <th>Applicant Name</th>
                    <th>Mobile Number</th>
                    <th>Address</th>

                    <th>Client Name</th>
                    <th>Case Reference Number</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.id}</td>
                      <td>{item?.applicantName}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.address}</td>

                      <td>{item?.clientName}</td>
                      <td>{item?.caseReferenceNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All;
