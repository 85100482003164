import React from "react";

function CorporateOwnership() {
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Employer Ownership
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Employer name</th>
                        <th>Employer Code </th>
                        <th>Owner name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>
                          Change in ownership request raised on dt and time{" "}
                        </th>
                        <th>New Owner name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Approve</th>
                        <th>Reject</th>
                        <th>Reject reason</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorporateOwnership;
