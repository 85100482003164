import React from 'react'

function FinancierEmployercommercials() {
  return (
    <div className="p-3">
    <div>
      <ul className="nav nav-tabs" id="myTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active tab-item "
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="true"
          >
           Financier-Employer commercials
          </button>
        </li>
      </ul>
      <div className="tab-content mt-3" id="myTabsContent">
        <div
          className="tab-pane fade show active"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div className="pt-3">
            <div className="card">
              <div className="p-3 pt-4">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Employer name/code</th>
                      <th>Set up fees</th>
                      <th>Convenience fees</th>
                      <th>Subvention payable to Salarytap</th>
                      <th>(This info will come from what is entered 
                        in Salarytap dashboard)</th>

                    </tr>
                  </thead>
                  <tbody>
                    {/* {manageEmp?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.name || "-"}</td>
                        <td>{item.status || "-"}</td>
                        <td
                          style={{
                            textAlign: "center",
                            whiteSpace: "normal",
                            fontSize: "15px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEmpDetails(true);
                          }}
                        >
                          {item.lid || "-"}
                        </td>
                        <td>{item.mobile || "-"}</td>
                        <td>{item.limitassigned || "-"}</td>
                        <td>{item.limitused || "-"}</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default FinancierEmployercommercials