import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Spinner from "react-bootstrap/Spinner";

import NavbarLogin from "./Navbar/Navbar";
import { APIURL } from "../../Config";
// import { useAppContext } from "../../Context/Listdata";

export default function SignUp() {
  // const { setAuthenticated } = useAppContext();
  const [show, setShow] = useState(true);
  const [signUpButton, setSignUpButton] = useState(false);
  const [forgetPwd, setForgetPwd] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    corporateName: Yup.string().required("Corporate Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobileNo: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be 10 characters at minimum")
      .max(10, "Mobile number must be 10 characters at maximum"),
    gstin: Yup.string().required("GSTIN is required"),
    onboardFlag: Yup.string().required("Select field is required"),
    address: Yup.string().required("Address is required"),
  });

  const backToWeb = () => {
    history("/");
  };

  React.useEffect(() => {
    // const tokenStatus = localStorage.getItem("token");
    // const role = localStorage.getItem("role");
    // //  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // if (!tokenStatus == "") {
    //   history("/admin");
    // }
  }, []);

  return (
    <div className="style">
      {/* <NavbarLogin /> */}
      <Formik
        initialValues={{
          corporateName: "",
          email: "",
          mobileNo: "",
          address: "",
          gstin: "",
          onboardFlag: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          setSignUpButton(true);
          console.log("values", values);

          //   const data = {
          //     mobileNo: values.mobileNo,
          //     password: values.password,
          //   };

          axios
            .post(`${APIURL}/customer/CorpAdminOnBoard`, values)
            .then((response) => {
              console.log("response::: ", response);

              if (response?.data?.success == true) {
                toast.success("Registration Successfully......");
                history("/");
              }

              // window.token = result.headers.authorization;
              // axios.defaults.headers.common["Authorization"] =
              //   result.headers.authorization;
              // localStorage.setItem("role", 1);
              // const userAuth = localStorage.getItem("role");
              // if (userAuth == 1) {
              //   history("/home");
              // }
              // if (userAuth == 2) {
              //   history("/home");
              // }
            })
            .catch((error) => {
              setSignUpButton(false);
              toast.warning("User not exist...");
            });
        }}
      >
        {({ touched, errors }) => (
          <>
            <Form
              className="Loginform"
              style={{ padding: "30px 25px 20px 25px" }}
            >
              <div>
                <div>
                  <h4
                    className="mt-3 mb-4 text-center"
                    style={{ color: "#66a725", fontWeight: 800 }}
                  >
                    {/* Give and Earn */}
                    SignUp
                  </h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Email*</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter Email"
                          className={`form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Mobile No*</label>
                        <Field
                          type="number"
                          name="mobileNo"
                          placeholder="Enter Mobile No"
                          className={`form-control
                          ${
                            touched.mobileNo && errors.mobileNo
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mobileNo"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Corporate Name*</label>
                        <Field
                          type="text"
                          name="corporateName"
                          placeholder="Enter Corporate Name"
                          className={`form-control
                          ${
                            touched.corporateName && errors.corporateName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="corporateName"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>GSTIN*</label>
                        <Field
                          type="text"
                          name="gstin"
                          placeholder="Enter GSTIN"
                          className={`form-control
                          ${touched.gstin && errors.gstin ? "is-invalid" : ""}`}
                        />
                        <ErrorMessage
                          component="div"
                          name="gstin"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mt-2">
                    <label>Address*</label>
                    <Field
                      type="text"
                      as="textarea"
                      rows={3}
                      name="address"
                      placeholder="Enter Address"
                      className={`form-control
                          ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="address"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">On Board Flag</label>
                    <Field
                      as="select"
                      name="onboardFlag"
                      className={` form-control
                          ${
                            touched.onboardFlag && errors.onboardFlag
                              ? "is-invalid"
                              : ""
                          }`}
                    >
                      <option value="">Select ...</option>
                      <option value="True">True</option>
                      <option value="False">False</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="onboardFlag"
                      className="invalid-feedback"
                    />
                  </div>
                  <button
                    className="btn btn-success mt-3"
                    type="submit"
                    style={{ backgroundColor: "#66a725" }}
                  >
                    Sign UP
                    {/* <Spinner animation="border" variant="dark" /> */}
                  </button>
                  {/* <hr /> */}
                  <div className="d-flex justify-content-center mt-3">
                    {" "}
                    <p>Already have an account? </p>&nbsp;
                    <Link className="" to={"/"} style={{ color: "#66a725" }}>
                      SignIn
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}
