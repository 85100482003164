import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import "../index.css";
import { toast } from "react-toastify";
import { APIURL } from "../../../Config";
import demoExcel from "../../../image/Book1.xlsx";
import Pagination from "../../Pagination/Pagination";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";
import { Modal } from "react-bootstrap";
import WorkspaceProfileDetails from "../../WorkspaceProfileDetails";

function ManageEmploye({ workSpace }) {
  const token = localStorage.getItem("token");
  const emailid = localStorage.getItem("emailId");
  const workspaceid = localStorage.getItem("workspace");
  const tableRef = React.useRef(null);
  const tableSummary = React.useRef(null);
  const tableUnpaid = React.useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const [manageEmp, SetManageEmp] = useState([]);
  const [empSummary, SetEmpSummary] = useState([]);
  const [empUnpaid, SetEmpUnpaid] = useState([]);
  const [empDetails, setEmpDetails] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [keyName, setKeyName] = useState("mobile");
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const [lid, setLid] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showPrePage, setShowPrePage] = useState(100);
  const fileInputRef = useRef(null);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const [showUpload, SetShowUpload] = useState(false);
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [limitExtend, setLimitExtend] = useState("");
  const [getlimitExtend, setGetLimitExtend] = useState("");

  const LoginSchema = Yup.object().shape({
    file: Yup.mixed().required("A file is required"),
  });

  const UploadSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    mobile: Yup.string()
      .required("Required")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
    limitassigned: Yup.string().required("Required"),
  });

  useEffect(() => {
    console.log("workSpace", workSpace);
    ManageEmployeesList();
  }, []);
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const searchHandler = () => {
    const search = { [keyName]: searchInput };
    setIsLoader(true);
    axios
      .request(`${APIURL}/employee/searchdetails`, {
        method: "POST",
        data: search,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        SetManageEmp(response?.data);

        setSearchInput("");
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  function ManageEmployeesList() {
    const data = { emailid };
    axios
      .request(`${APIURL}/employee/manageemployees`, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        SetManageEmp(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const summary = () => {
    const data = { email: emailid };
    axios
      .request(`${APIURL}/employee/summary`, {
        method: "POST",
        data: data,
        headers: {
          Authentication: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (typeof response?.data !== "string") {
          SetEmpSummary(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonth("0");
  }, []);

  const Unpaidemployees = () => {
    const data = { email: emailid, status: month };
    setIsLoader(true);
    axios
      .request(`${APIURL}/employee/unpaidreport`, {
        method: "POST",
        data: data,
        headers: {
          Authentication: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (typeof response?.data !== "string") {
          SetEmpUnpaid(response?.data);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const limitExtendChange = (e) => {
    setLimitExtend(e.target.value);
  };
  const handleSubmit = (limitExtend) => {
    if (getlimitExtend.limitassigned > limitExtend) {
      toast.warning("Invalid limit Extend...");
    } else {
      const data = {
        emailid: emailid,
        mobile: getlimitExtend.mobile,
        limitassigned: limitExtend,
      };
      axios
        .request(`${APIURL}/corporate/updatelimitassigned`, {
          method: "POST",
          data: data,
          headers: {
            Authentication: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response", response);
          setShowModal(false);
          ManageEmployeesList();
          toast.success("limit Extend successfully...");
        })
        .catch((error) => {
          console.log("error", error);
          toast.warning("Invalid...");
        });
    }
  };

  return (
    <div className="p-md-3 pt-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="admin-tab"
              data-bs-toggle="tab"
              data-bs-target="#admin"
              type="button"
              role="tab"
              aria-controls="admin"
              aria-selected="false"
              onClick={() => SetShowUpload(false)}
            >
              Upload employee data
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link  tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
              onClick={() => ManageEmployeesList()}
            >
              Manage employees
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="upload-tab"
              data-bs-toggle="tab"
              data-bs-target="#upload"
              type="button"
              role="tab"
              aria-controls="upload"
              aria-selected="false"
              onClick={summary}
            >
              Employee summary
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item "
              id="agreement-tab"
              data-bs-toggle="tab"
              data-bs-target="#agreement"
              type="button"
              role="tab"
              aria-controls="agreement"
              aria-selected="false"
              onClick={Unpaidemployees}
            >
              Unpaid employees
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade  "
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {!empDetails ? (
              <div className="pt-3">
                <div className="card">
                  <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <DownloadTableExcel
                      filename="Employee Details"
                      sheet="users"
                      currentTableRef={tableRef.current}
                    >
                      <button
                        className="btn btn-success mb-3 mb-md-0"
                        style={{ width: "120px", background: "#66a725" }}
                      >
                        Download
                      </button>
                    </DownloadTableExcel>
                    <div className="d-flex flex-column flex-md-row align-items-center">
                      <select
                        className="form-select mx-md-2 mb-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setKeyName(e.target.value)}
                        value={keyName}
                      >
                        <option value="mobile">Mobile No.</option>
                        <option value="lid">Loan id</option>
                        <option value="name">Name</option>
                      </select>
                      <input
                        className="form-control"
                        style={{
                          height: "38px",
                          width: "100%",
                          background: "#fff",
                        }}
                        type="text"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleInputChange}
                      />
                      <button
                        className="btn btn-success ml-md-2 mt-2 mt-md-0"
                        style={{ background: "#66a725", color: "#fff" }}
                        onClick={searchHandler}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="p-3 pt-2">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Lid</th>
                            <th>Limit assigned</th>
                            <th>Limit used</th>
                            <th style={{ width: "150px" }}>Limit Extend</th>
                          </tr>
                        </thead>
                        <tbody>
                          {manageEmp
                            ?.slice(pagination.start, pagination.end)
                            .map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1 + pagination.start}</td>
                                <td>{item.name}</td>
                                <td>{item.mobile}</td>
                                <td
                                  onClick={() => {
                                    if (item.lid) {
                                      setEmpDetails(true);
                                      setLid(item.lid);
                                    }
                                  }}
                                  style={{ cursor: "pointer", color: "blue" }}
                                >
                                  {item.lid}
                                </td>
                                <td>{item.limitassigned}</td>
                                <td>{item.limitused}</td>
                                <td>
                                  <button
                                    className="btn btn-success"
                                    style={{ width: "100px" }}
                                    onClick={() => {
                                      setShowModal(true);
                                      setLimitExtend(item.limitassigned);
                                      setGetLimitExtend(item);
                                    }}
                                  >
                                    Extend
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        style={{ background: "rgb(255 255 255 / 40%)" }}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Limit Extend</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <input
                            type="number"
                            value={limitExtend}
                            onChange={limitExtendChange}
                            style={{
                              width: "100%",
                              padding: "10px",
                              boxSizing: "border-box",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "10px",
                              fontSize: "18px",
                            }}
                          />
                          <div className="d-flex mt-3 justify-content-end">
                            <button
                              className="btn btn-secondary"
                              style={{ width: "100px" }}
                              onClick={() => setShowModal(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-success ml-2"
                              style={{
                                width: "100px",
                                background: "#66a725",
                              }}
                              onClick={() => handleSubmit(limitExtend)}
                            >
                              Submit
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                    {manageEmp && manageEmp.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={manageEmp.length}
                      />
                    )}
                    <table
                      className="table table-striped"
                      style={{ display: "none" }}
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Mobile</th>
                          <th>Limit assigned</th>
                          <th>Limit used</th>
                        </tr>
                      </thead>
                      <tbody>
                        {manageEmp?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.mobile}</td>
                            <td>{item.limitassigned}</td>
                            <td>{item.limitused}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <button
                  className="btn btn-secondary mt-3"
                  onClick={() => setEmpDetails(false)}
                  style={{ width: "80px" }}
                >
                  Back
                </button>
                <WorkspaceProfileDetails lid={lid} />
              </>
            )}
          </div>

          <div
            className="tab-pane fade show active"
            id="admin"
            role="tabpanel"
            aria-labelledby="admin-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-4">
                  {workSpace?.assuranceTc &&
                  workSpace?.model !== null &&
                  workSpace?.address !== null &&
                  workSpace?.companyType !== null &&
                  workSpace?.hrApp !== null &&
                  workSpace?.fundEmployees !== null ? (
                    <>
                      <div className="d-flex justify-content-end">
                        {!showUpload ? (
                          <button
                            className="mr-3 btn btn-success"
                            style={{ width: "120px", background: "#66a725" }}
                            onClick={() => SetShowUpload(true)}
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            className="mr-3 btn btn-secondary"
                            style={{ width: "120px" }}
                            onClick={() => SetShowUpload(false)}
                          >
                            Back
                          </button>
                        )}
                      </div>
                      {!showUpload ? (
                        <Formik
                          initialValues={{
                            file: null,
                          }}
                          validationSchema={LoginSchema}
                          onSubmit={(values, { resetForm }) => {
                            const formData = new FormData();
                            formData.append("Files", values.file);
                            formData.append("emailid", emailid);
                            formData.append("workspaceId", workspaceid);
                            for (let pair of formData.entries()) {
                              console.log(pair[0], pair[1]);
                            }
                            setIsLoader(true);
                            axios
                              .request(`${APIURL}/employee/detailsupload`, {
                                method: "POST",
                                data: formData,
                                headers: {
                                  Authentication: token,
                                },
                              })
                              .then((response) => {
                                console.log("response::", response);
                                setIsLoader(false);
                                toast.success(response?.data);
                                resetForm();
                                fileInputRef.current.value = null;
                              })
                              .catch((error) => {
                                console.log("error", error);
                                setIsLoader(false);
                                toast.warning("Invalid...");
                                fileInputRef.current.value = null;
                                resetForm();
                              });
                          }}
                        >
                          {({ touched, errors, setFieldValue }) => (
                            <>
                              <Form className="pt-4">
                                <div
                                  className="row"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-lg-9 col-md-9 col-sm-12">
                                    <div className="form-group">
                                      <label htmlFor="file">Upload File</label>
                                      <input
                                        id="file"
                                        name="file"
                                        type="file"
                                        accept=".xlsx, .xls"
                                        ref={fileInputRef}
                                        className={`form-control ${
                                          touched.file && errors.file
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        onChange={(event) => {
                                          const file =
                                            event.currentTarget.files[0];
                                          setFieldValue("file", file);
                                        }}
                                      />
                                      <ErrorMessage
                                        name="file"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3 col-sm-12  d-flex justify-content-center">
                                    <a
                                      href={demoExcel}
                                      style={{
                                        width: "200px",
                                        backgroundColor: "#6c757d",
                                      }}
                                      className="btn btn-secondary mt-3"
                                    >
                                      Download Demo File
                                    </a>
                                  </div>
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-success mt-3"
                                  style={{ backgroundColor: "#66a725" }}
                                >
                                  {isLoader ? (
                                    <div
                                      className="spinner-border text-success"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </Form>
                            </>
                          )}
                        </Formik>
                      ) : (
                        <Formik
                          initialValues={{
                            name: "",
                            mobile: "",
                            limitassigned: "",
                          }}
                          // validationSchema={UploadSchema}
                          onSubmit={(values, { resetForm }) => {
                            if (
                              !values.name ||
                              !values.mobile ||
                              !values.limitassigned
                            ) {
                              toast.warning("all field required...");
                            } else {
                              const data = {
                                name: values.name,
                                mobile: values.mobile,
                                limitassigned: values.limitassigned,
                                emailid: emailid,
                                workspaceId: workspaceid,
                              };
                              setIsLoader(true);
                              axios
                                .request(`${APIURL}/corporate/addemployee`, {
                                  method: "POST",
                                  data: data,
                                  headers: {
                                    Authentication: token,
                                  },
                                })
                                .then((response) => {
                                  setIsLoader(false);
                                  SetShowUpload(false);
                                  toast.success(response?.data);
                                  resetForm();
                                })
                                .catch((error) => {
                                  console.log("error", error);
                                  setIsLoader(false);
                                  SetShowUpload(false);
                                  toast.warning("Invalid...");
                                  resetForm();
                                });
                            }
                          }}
                        >
                          {({ touched, errors }) => (
                            <Form className="pt-4">
                              <div>
                                <label>Your Name*</label>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Enter name"
                                  className={`form-control ${
                                    touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mt-2">
                                <label>Mobile*</label>
                                <Field
                                  type="number"
                                  name="mobile"
                                  placeholder="Enter mobile"
                                  className={`form-control ${
                                    touched.mobile && errors.mobile
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="mobile"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mt-2">
                                <label>Limit Assigned*</label>
                                <Field
                                  type="text"
                                  name="limitassigned"
                                  placeholder="Enter limit assigned"
                                  className={`form-control ${
                                    touched.limitassigned &&
                                    errors.limitassigned
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="limitassigned"
                                  className="invalid-feedback"
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn btn-success mt-3"
                                style={{ backgroundColor: "#66a725" }}
                              >
                                {isLoader ? (
                                  <div
                                    className="spinner-border text-success"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        className="m-0"
                        style={{
                          color: "red",
                          fontSize: "22px",
                        }}
                      >
                        Complete your profile
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="upload"
            role="tabpanel"
            aria-labelledby="upload-tab"
          >
            {" "}
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="d-flex">
                    <DownloadTableExcel
                      filename="Employee Summary"
                      sheet="users"
                      currentTableRef={tableSummary.current}
                    >
                      <button
                        className="btn btn-success border"
                        style={{ width: "120px", backgroundColor: "#66a725" }}
                      >
                        {" "}
                        Download{" "}
                      </button>
                    </DownloadTableExcel>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped mt-3">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Month </th>
                          <th>Uploaded</th>
                          <th>Approved</th>
                          <th>Rejected</th>
                          {/* <th >Limit Count</th> */}
                          <th>Limit Amount assigned</th>
                          <th>Utilization Amount</th>
                          {/* <th >Utilization Count</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {empSummary?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.month}</td>
                            <td>{item.uploaded}</td>
                            <td>{item.approved}</td>
                            <td>{item.rejected}</td>
                            {/* <td>{item.limitcount}</td>.\ */}
                            <td>{item.limitamountassigned}</td>
                            <td>{Number(item.utilizationamount).toFixed(2)}</td>
                            {/* <td>{Number(item.utilizationcount).toFixed(2)}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <table
                    className="table table-striped mt-3"
                    style={{ display: "none" }}
                    ref={tableSummary}
                  >
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Month </th>
                        <th>Approved</th>
                        <th>Rejected</th>
                        <th>Uploaded</th>
                        <th>Limit Count</th>
                        <th>Limit Amount assigned</th>
                        <th>Utilization Amount</th>
                        <th>Utilization Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {empSummary.length > 0 &&
                        empSummary?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.month}</td>
                            <td>{item.approved}</td>
                            <td>{item.rejected}</td>
                            <td>{item.uploaded}</td>
                            <td>{item.limitcount}</td>
                            <td>{item.limitamountassigned}</td>
                            <td>{Number(item.utilizationamount).toFixed(2)}</td>
                            <td>{Number(item.utilizationcount).toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="agreement"
            role="tabpanel"
            aria-labelledby="agreement-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 d-flex justify-content-between align-items-center flex-md-row flex-column flex-md-row">
                  <div className="d-flex mb-3 mb-md-0">
                    <DownloadTableExcel
                      filename="Employee Unpaid"
                      sheet="users"
                      currentTableRef={tableUnpaid.current}
                    >
                      <button
                        className="btn btn-success border"
                        style={{ width: "120px", backgroundColor: "#66a725" }}
                      >
                        Download
                      </button>
                    </DownloadTableExcel>
                  </div>
                  <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center">
                    <div>
                      <select
                        className="form-select mx-md-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                      >
                        {months.map(({ name, value }) => (
                          <option key={value} value={value}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-md-2">
                      <button
                        className="btn btn-success border"
                        style={{ background: "#66a725", color: "#fff" }}
                        onClick={Unpaidemployees}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="p-3 pt-2 table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Limit assigned</th>
                        <th>Limit used</th>
                        <th>Unpaid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {empUnpaid &&
                        empUnpaid?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.mobile}</td>
                            <td>{item.limitassigned}</td>
                            <td>{item.limitused}</td>
                            <td>{Number(item.unpaid).toFixed(2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <table
                  className="table table-striped mt-3 "
                  style={{ display: "none" }}
                  ref={tableUnpaid}
                >
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Limit assigned</th>
                      <th>Limit used</th>
                      <th>Unpaid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empUnpaid?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.mobile}</td>
                        <td>{item.limitassigned}</td>
                        <td>{item.limitused}</td>
                        <td>{Number(item.unpaid).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageEmploye;
